<template>
	<div>
		<template v-if="addressLoading || fetchingCardLoading">
			<is-loading
				:loading="addressLoading || fetchingCardLoading"
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')" />
		</template>
		<template v-else>
			<template v-if="updateType === 'address' && getImage !== undefined">
				<b-row>
					<b-col cols="auto">
						<img
							:src="require(`@/assets/images/common/cc/logos/${getImage}.png`)"
							align="left"
							class="text-right">
					</b-col>
					<b-col>
						<b-row>
							{{ translate('last_digits') }}<b class="ml-1">{{ form.last_digits ? form.last_digits : 'XXXX' }} </b>
						</b-row>
						<b-row>
							<b>{{ `${getCardInfo(form, 'first_name')} ${getCardInfo(form, 'last_name')}` }}</b>
						</b-row>
					</b-col>
				</b-row>
				<hr class="mb-4">
			</template>
			<b-card
				v-if="!hideNewAddress"
				:style="{ border: borderless ? 'none' : '' }"
				:class="{ 'mb-4': !borderless, 'mb-0': borderless }"
				class="h-100 mx-0">
				<b-form-group class="mb-0">
					<b-card-text class="mb-2">
						<template v-if="!desegregateGiftAddresses">
							<template v-if="showGiftAddresses">
								<div class="text-success mb-2">
									{{ translate('my_gift_addresses') }}
								</div>
								<b-form-radio
									v-for="(item) in giftAddresses"
									:key="item.id"
									v-model="form.address_id"
									:value="item.id"
									:name="buttonGroupName">
									<b>{{ `${getCardInfo(item.attributes, 'first_name')} ${getCardInfo(item.attributes, 'last_name')}` }}</b>,&nbsp;{{ `${getCardInfo(item.attributes, 'telephone')}${getCardInfo(item.attributes, 'address')} ${getCardInfo(item.attributes, 'address2')}, ${getCardInfo(item.attributes, 'city')}, ${getCardInfo(item.attributes, 'region')}, ${getCardInfo(item.attributes, 'postcode')}, ${getCardInfo(item.attributes, 'country')}` }}
								</b-form-radio>
								<hr>
							</template>
							<div
								v-if="pickupAddresses.length || (showGiftAddresses && giftAddresses.length)"
								class="text-success mt-2 mb-1">
								{{ translate('my_addresses') }}
							</div>
							<b-form-radio
								v-for="(item) in normalAddresses"
								:key="item.id"
								v-model="form.address_id"
								:value="item.id"
								:name="buttonGroupName">
								<b>{{ `${getCardInfo(item.attributes, 'first_name')} ${getCardInfo(item.attributes, 'last_name')}` }}</b>,&nbsp;{{ `${getCardInfo(item.attributes, 'telephone')}${getCardInfo(item.attributes, 'address')} ${getCardInfo(item.attributes, 'address2')}, ${getCardInfo(item.attributes, 'city')}, ${getCardInfo(item.attributes, 'region')}, ${getCardInfo(item.attributes, 'postcode')}, ${getCardInfo(item.attributes, 'country')}` }}
							</b-form-radio>
							<template v-if="pickupAddresses.length">
								<div class="text-success my-2">
									{{ translate('my_pickup_addresses') }}
								</div>
								<b-form-radio
									v-for="(item) in pickupAddresses"
									:key="item.id"
									v-model="form.address_id"
									:value="item.id"
									:name="buttonGroupName">
									<b>{{ `${getCardInfo(item.attributes, 'first_name')} ${getCardInfo(item.attributes, 'last_name')}` }}</b>,&nbsp;{{ `${getCardInfo(item.attributes, 'telephone')}${getCardInfo(item.attributes, 'address')} ${getCardInfo(item.attributes, 'address2')}, ${getCardInfo(item.attributes, 'city')}, ${getCardInfo(item.attributes, 'region')}, ${getCardInfo(item.attributes, 'postcode')}, ${getCardInfo(item.attributes, 'country')}` }}
								</b-form-radio>
							</template>
						</template>
						<template v-else>
							<div
								v-if="pickupAddresses.length"
								class="text-success mt-2 mb-1">
								{{ translate('my_addresses') }}
							</div>
							<b-form-radio
								v-for="(item) in addressData"
								:key="item.id"
								v-model="form.address_id"
								:value="item.id"
								:name="buttonGroupName">
								<b>{{ `${getCardInfo(item.attributes, 'first_name')} ${getCardInfo(item.attributes, 'last_name')}` }}</b>,&nbsp;{{ `${getCardInfo(item.attributes, 'telephone')}${getCardInfo(item.attributes, 'address')} ${getCardInfo(item.attributes, 'address2')}, ${getCardInfo(item.attributes, 'city')}, ${getCardInfo(item.attributes, 'region')}, ${getCardInfo(item.attributes, 'postcode')}, ${getCardInfo(item.attributes, 'country')}` }}
							</b-form-radio>
							<template v-if="pickupAddresses.length">
								<div class="text-success my-2">
									{{ translate('my_pickup_addresses') }}
								</div>
								<b-form-radio
									v-for="(item) in pickupAddresses"
									:key="item.id"
									v-model="form.address_id"
									:value="item.id"
									:name="buttonGroupName">
									<b>{{ `${getCardInfo(item.attributes, 'first_name')} ${getCardInfo(item.attributes, 'last_name')}` }}</b>,&nbsp;{{ `${getCardInfo(item.attributes, 'telephone')}${getCardInfo(item.attributes, 'address')} ${getCardInfo(item.attributes, 'address2')}, ${getCardInfo(item.attributes, 'city')}, ${getCardInfo(item.attributes, 'region')}, ${getCardInfo(item.attributes, 'postcode')}, ${getCardInfo(item.attributes, 'country')}` }}
								</b-form-radio>
							</template>
						</template>
					</b-card-text>
					<b-card-text
						v-if="displayAddNewAddress">
						<b-button
							class="pl-0"
							variant="link"
							@click="$emit('add-address')">
							<i
								class="fa fa-plus-circle"
								aria-hidden="true" />
							{{ translate('add_new_address') }}
						</b-button>
					</b-card-text>
				</b-form-group>
			</b-card>
		</template>
	</div>
</template>

<script>
import { Profile, Countries, Purchase } from '@/translations';
import IsLoading from '@/components/Loading';

export default {
	name: 'AddressRadioButton',
	messages: [Profile, Countries, Purchase],
	components: {
		IsLoading,
	},
	props: {
		addressLoading: {
			type: Boolean,
			default: false,
		},
		fetchingCardLoading: {
			type: Boolean,
			default: false,
		},
		addressData: {
			type: Array,
			required: true,
		},
		formImport: {
			type: Object,
			default() {
				return {};
			},
		},
		updateType: {
			type: String,
			default: '',
		},
		hideNewAddress: {
			type: Boolean,
			default: false,
		},
		displayAddNewAddress: {
			type: Boolean,
			default: true,
		},
		borderless: {
			type: Boolean,
			default: false,
		},
		buttonGroupName: {
			type: String,
			default: 'address-radio',
		},
		showGiftAddresses: {
			type: Boolean,
			default: false,
		},
		desegregateGiftAddresses: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			form: this.formImport,
		};
	},
	computed: {
		getImage() {
			let image = 'cc';
			if (this.form.card_type !== null && this.form.card_type !== '') {
				image = this.form.card_type;
			}
			return image;
		},
		giftAddresses() {
			return this.addressData.filter((item) => item.attributes.is_gift_address);
		},
		normalAddresses() {
			return this.addressData.filter((item) => !item.attributes.is_gift_address && !item.attributes.is_pickup_address);
		},
		pickupAddresses() {
			return this.addressData.filter((item) => item.attributes.is_pickup_address);
		},
	},
	methods: {
		getCardInfo(value, index) {
			try {
				if (value[index] !== null && value[index] !== undefined && value[index] !== '') {
					if (index === 'country') return this.translate((value.country.code).toLowerCase());
					if (index === 'region') return value[index].name.toUpperCase();
					if (index === 'telephone') return `${value[index]}, `;
					if (['city', 'card_type'].includes(index)) {
						return (value[index]).toUpperCase();
					}
					return value[index];
				}
				if (index === 'card_type') return 'CC';
				return '';
			} catch (e) {
				if (index === 'card_type') return 'CC';
				return '';
			}
		},
	},
};
</script>
