<template>
	<ul class="list-group w-100">
		<slot />
	</ul>
</template>
<script>
export default {
	name: 'FlowInfoGroup',
};
</script>
