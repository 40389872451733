import moment from 'moment';

export default {
	data() {
		return {
			diffTime: '',
			diffFromStart: '',
			timerId: '',
			useSeconds: false,
			interval: 100000,
			timezone: '',
		};
	},
	computed: {
		days() {
			if (this.diffTime >= 0) {
				if (!this.useSeconds) {
					return this.checkTime(Math.floor((((this.diffTime / 1000) / 60) / 60) / 24));
				}
			}
			return '00';
		},
		hours() {
			if (this.diffTime >= 0) {
				if (!this.useSeconds) {
					return this.checkTime(Math.floor((((this.diffTime / 1000) / 60) / 60) % 24));
				}
				return this.checkTime(Math.floor((((this.diffTime / 1000) / 60) / 60)));
			}
			return '00';
		},
		minutes() {
			if (this.diffTime >= 0) {
				if (!this.useSeconds) {
					return this.checkTime(Math.floor(((this.diffTime / 1000) / 60) % 60));
				}
				return this.checkTime(Math.floor(((this.diffTime / 1000) / 60) % 60));
			}
			return '00';
		},
		seconds() {
			if (this.diffTime >= 0) {
				if (this.useSeconds) {
					return this.checkTime(Math.floor((this.diffTime / 1000) % 60 % 60));
				}
			}
			return '00';
		},
		countdownStarted() {
			return this.diffFromStart <= 0;
		},
		countdownDone() {
			return this.diffTime < 0;
		},
		isBetweenInterval() {
			return this.countdownStarted && !this.countdownDone;
		},
	},
	beforeDestroy() {
		clearInterval(this.timerId);
	},
	methods: {
		checkTime(number) {
			return number > 9 ? number : '0'.concat(number);
		},
		countdown(endDate, showFrom = '') {
			// Start countdown finding the initial deference between dates
			clearInterval(this.timerId);
			let diff = moment(endDate).diff(moment());
			if (this.timezone.length > 0) {
				const now = moment.tz(this.timezone);
				const endDateFormat = moment.tz(endDate, this.timezone);
				diff = endDateFormat.diff(now);
			}
			this.diffTime = moment.duration(diff).asMilliseconds();

			if (showFrom.length > 0) {
				let diffStart = moment(showFrom).diff(moment());
				if (this.timezone.length > 0) {
					const now = moment.tz(this.timezone);
					const startDateFormat = moment.tz(showFrom, this.timezone);
					diffStart = startDateFormat.diff(now);
				}

				this.diffFromStart = moment.duration(diffStart).asMilliseconds();
			}
			this.runTimer();
			this.timerId = setInterval(this.runTimer, this.interval);
		},
		runTimer() {
			if (this.useSeconds) {
				this.diffTime -= 1000; // 1 second
				this.diffFromStart -= 1000; // 1 second
			} else {
				this.diffTime -= 60000; // 1 minute
				this.diffFromStart -= 60000; // 1 minute
			}
		},
	},
};
