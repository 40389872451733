<template>
	<div
		class="col-12 mt-3">
		<div class="font-weight-bold mb-4">
			<span class="h5 align-top">{{ translate(ticketCodeName) }}</span>
		</div>
		<div
			v-for="(_, i) in quantity"
			:key="i">
			<h6 v-if="ticketCount > 1 && quantity > 1">
				{{ translate('ticket') }} {{ i + 1 }}
			</h6>
			<div
				v-for="(n, j) in ticketCount"
				:key="`${i}.${j}`">
				<label>
					{{ ticketCount > 1 ? (j === 0 ? translate('your_info') : translate('companion_info')) : `${translate('attendee')} ${ticketCount > 1 ? j + 1 : i + 1}` }}
					<span class="text-danger">*</span>
				</label>
				<div
					v-if="ticketCount > 1 && j > 0"
					class="row">
					<div class="col-12 col-md-6">
						<div class="form-group mb-3">
							<search
								ref="attendantId"
								v-model="tempIds[`${i}.${j}`]"
								:endpoint="endpoint"
								:empty-result-message="translate('data_not_found')"
								:placeholder="'autocomplete_uid'"
								:default-params="{role:'distributor', tree:'unilevel'}"
								:init-value="String(getName('attendant_id', i, j))"
								return-key="id"
								class="mb-3"
								q="label"
								@result="handleUser($event, i, j)" />
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="form-group mb-3">
							<select-item
								:options="companionTypes"
								show-label
								:first-option="translate('companion_type_placeholder')"
								:setter-value="getName('companion_type', i, j)"
								@dataChanged="handleChange('companion_type', $event, i, j)" />
						</div>
					</div>
					<div class="col-12">
						<b-alert
							:show="String(getName('attendant_id', i, j)) !== ''"
							variant="warning">
							<b>{{ translate('important') }}:</b> <span>{{ translate('auto_transfer_alert') }}</span>
						</b-alert>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-md-6">
						<div class="form-group mb-0">
							<input-text
								:id="`${ticketCodeName}.tickets.${i}.${j}.first_name`"
								:placeholder="translate('first_name')"
								:setter-value="getName('first_name', i, j)"
								:required="true"
								type="text"
								@dataChanged="handleChange('first_name', $event, i, j)" />
						</div>
					</div>
					<div class="col-12 col-md-6">
						<div class="form-group mb-0">
							<input-text
								:id="`${ticketCodeName}.tickets.${i}.${j}.last_name`"
								:placeholder="translate('last_name')"
								:setter-value="getName('last_name', i, j)"
								:required="false"
								type="text"
								@dataChanged="handleChange('last_name', $event, i, j)" />
						</div>
					</div>
				</div>
			</div>
			<!-- <div>
				<b-row>
					<b-col>
						<div class="form-group">
							<label
								for="check_in_date"
								class="label font-weight-bold">{{ translate('check_in_date') }}
								<span class="text-danger">*</span></label>
							<select-item
								:options="checkInDates"
								show-label
								:setter-value="getName('check_in_date', i) || (checkInDates.length > 0 ? checkInDates[0].value : '')"
								@dataChanged="handleChange('check_in_date', $event, i)" />
						</div>
					</b-col>
					<b-col>
						<b-form-group class="form-group">
							<label
								:for="`check_out_date_${i}`"
								class="label font-weight-bold">{{ translate('check_out_date') }}
								<span class="text-danger">*</span></label>
							<input
								:id="`check_out_date_${i}`"
								:value="getName('check_out_date', i)"
								disabled
								class="form-control"
								type="text">
						</b-form-group>
					</b-col>
				</b-row>
			</div> -->
			<div
				v-if="ticketCount > 1"
				class="row">
				<div class="col-12 col-md-6 mb-3">
					<select-item
						:options="bedTypes"
						show-label
						required
						:label="translate('bed_type')"
						:first-option="translate('bed_type_placeholder')"
						:setter-value="getName('bed_type', i)"
						@dataChanged="handleChange('bed_type', $event, i)" />
				</div>
			</div>
		</div>
		<div
			v-if="showGlobalBedType"
			class="row">
			<div class="col-12 col-md-6 mb-3">
				<select-item
					:options="bedTypes"
					show-label
					required
					:label="translate('bed_type')"
					:first-option="translate('bed_type_placeholder')"
					:setter-value="getName('bed_type', 0)"
					@dataChanged="handleChange('bed_type', $event, 0)" />
			</div>
		</div>
	</div>
</template>
<script>
import InputText from '@/components/InputText';
import SelectItem from '@/components/Select';
import { Events, Products, EventTickets } from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';
import { SEARCH_USERS } from '@/config/endpoint';
import { DDDDMMMDO_FORMAT, YMD_FORMAT } from '@/settings/Dates';

const { endpoint } = SEARCH_USERS;
export default {
	messages: [Events, Products, EventTickets],
	components: {
		InputText,
		SelectItem,
	},
	mixins: [WindowSizes],
	props: {
		ticketCodeName: {
			type: String,
			default: '',
		},
		quantity: {
			type: Number,
			default: 0,
		},
		ticketCount: {
			type: Number,
			default: 1,
		},
		totalTickets: {
			type: Number,
			default: 1,
		},
		names: {
			type: Object,
			default: () => ({}),
		},
		companionTypes: {
			type: Array,
			default: () => [],
		},
		bedTypes: {
			type: Array,
			default: () => [],
		},
		bedTypesVisibility: {
			type: Object,
			default: () => ({}),
		},
		checkInDates: {
			type: Array,
			default: () => [],
		},
		reservationNights: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			attendees: this.names,
			ordinalDateFormat: DDDDMMMDO_FORMAT,
			dateFormat: YMD_FORMAT,
			endpoint,
			tempIds: {},
		};
	},
	computed: {
		showGlobalBedType() {
			const showVisibility = typeof this.bedTypesVisibility !== 'undefined';
			if (showVisibility) {
				return (this.bedTypesVisibility.tickets_count ?? 0) === this.totalTickets;
			}
			return false;
		},
	},
	watch: {
		names() {
			this.attendees = this.names;
		},
	},
	methods: {
		handleChange(nameField, value, i, j) {
			if (typeof this.attendees[i] === 'undefined') {
				this.attendees[i] = {
					bed_type: '',
					check_in_date: '',
					check_out_date: '',
				};
			}

			if (nameField === 'check_in_date') {
				const checkOutDate = this.$moment(value).add(this.reservationNights, 'days').toDate();
				this.attendees[i].check_out_date = this.$moment(checkOutDate).format(this.dateFormat);
				this.$forceUpdate();
			}

			if (typeof j === 'undefined') {
				this.attendees[i][nameField] = value;
			} else {
				if (typeof this.attendees[i][j] === 'undefined') {
					this.attendees[i][j] = {
						attendant_id: '',
						companion_type: '',
						first_name: '',
						last_name: '',
					};
				}
				this.attendees[i][j][nameField] = value;
			}
			this.$emit('dataChanged', this.attendees);
		},
		getName(nameField, i, j) {
			if (typeof this.names[i] === 'undefined') {
				return '';
			}

			if (typeof j === 'undefined') {
				if (nameField === 'check_out_date') {
					return this.$moment(this.names[i][nameField]).format(this.ordinalDateFormat);
				}
				return this.names[i][nameField] || '';
			}

			if (typeof this.names[i][j] === 'undefined') {
				return '';
			}
			return this.names[i][j][nameField];
		},
		handleUser(data, i, j) {
			if (data.first_name) {
				this.handleChange('first_name', data.first_name, i, j);
			}
			if (data.last_name) {
				this.handleChange('last_name', data.last_name, i, j);
			}

			this.tempIds = { ...this.tempIds };
			const attendantId = this.$refs.attendantId[i].query;
			this.handleChange('attendant_id', attendantId, i, j);
		},
	},
};
</script>
