<template>
	<b-modal
		ref="freeProductsModal"
		scrollable
		no-close-on-backdrop
		header-class="mx-auto w-100 bg-dark text-white"
		:size="['xs', 'sm'].includes(windowWidth) ? 'xl' : 'lg-custom'"
		:body-class="['xs'].includes(windowWidth) ? 'p-2' : ''"
		centered
		@hide="$emit('close')">
		<template v-slot:modal-header>
			<h5 class="modal-title text-white">
				{{ translate('title') }}
			</h5>
		</template>
		<div
			class="row no-gutters justify-content-center">
			<div
				:class="['xs', 'sm'].includes(windowWidth) ? '' : 'px-2'"
				class="col-12">
				<div
					v-for="(section, index) in sections.filter((sec) => Array.isArray(sec.valid_products) ? sec.valid_products.length > 0 : Object.keys(sec.valid_products).length > 0)"
					:key="index"
					class="border-secondary mb-2 px-2 pt-2 pb-0"
					style="border: 1px solid black; border-radius: 5px">
					<div
						class="d-flex justify-content-between pointer"
						@click="toggleCollapse(index)">
						<div class="d-flex align-items-center">
							<p class="mb-2">
								<b><i :class="`fas fa-chevron-${collapse[index] ? 'down' : 'right'}`" /> {{ translate(sectionName(section)) }}</b>
							</p>
						</div>
						<div
							v-if="section.type === 'product_list'"
							class="mr-3">
							<p
								class="mb-2"
								:class="(section.selected_qty || 0) !== section.qty ? 'text-danger' : 'text-discount'">
								<b>{{ (section.selected_qty || 0) }}/{{ section.qty }}</b>
							</p>
						</div>
					</div>
					<b-collapse :visible="collapse[index]">
						<template v-if="section.type === 'product_list'">
							<div class="row mx-2 mt-1 mb-3">
								<div class="col-12">
									<div
										v-for="(product, i) in section.valid_products"
										:key="i"
										:class="i < section.valid_products.length - 1 ? 'border border-secondary border-top-0 border-left-0 border-right-0 mb-2' : ''">
										<configurable-product
											:code-name="product.code_name"
											:config-name="''"
											:sku="''"
											:thumbnail="product.image"
											:stacked-layout="['xs', 'sm'].includes(windowWidth)">
											<div class="row">
												<div
													v-for="(child, j) in product.children"
													:key="j"
													class="col-12 col-md-6">
													<product-configuration
														:configuration="child.code_name"
														:init-qty="selected[sectionName(section)] ? (selected[sectionName(section)][child.sku] || 0) : 0"
														:stacked-layout="['xs', 'sm'].includes(windowWidth)"
														@change="handleQtyChange(section, child.sku, $event)" />
												</div>
											</div>
										</configurable-product>
									</div>
								</div>
							</div>
						</template>
						<template v-else-if="section.type === 'dropdown'">
							<select
								v-model="selected[sectionName(section)]"
								:value="selected[sectionName(section)]"
								type="text"
								class="form-control mb-3">
								<option
									v-for="(product, sku) in section.valid_products"
									:key="sku"
									:value="sku">
									{{ translate(product.label) }}
								</option>
							</select>
						</template>
					</b-collapse>
				</div>
			</div>
		</div>
		<template v-slot:modal-footer>
			<div class="col col-12 no-gutters justify-content-end text-right mt-3">
				<b-button
					type="button"
					aria-label="Close"
					class="mr-2"
					@click="close()">
					{{ !!cancelTitle ? cancelTitle : translate('cancel') }}
				</b-button>
				<b-button
					type="button"
					variant="primary"
					aria-label="Close"
					:disabled="confirmDisabled"
					@click="confirm()">
					{{ !!okTitle ? okTitle : translate('confirm') }}
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
import ConfigurableProducts from '@/mixins/ConfigurableProducts';
import WindowSizes from '@/mixins/WindowSizes';
import EventBus from '@/util/eventBus';
import { FreeProductsModal, Products } from '@/translations';
import ProductConfiguration from '@/components/AddAutoshipModal/ProductConfiguration';
import ConfigurableProduct from '@/components/AddAutoshipModal/ConfigurableProduct';

export default {
	name: 'FreeProductsModal',
	messages: [Products, FreeProductsModal],
	components: {
		ProductConfiguration,
		ConfigurableProduct,
	},
	mixins: [ConfigurableProducts, WindowSizes],
	props: {
		sectionsImport: {
			type: Array,
			default: () => [],
		},
		okTitle: {
			type: String,
			default: '',
		},
		cancelTitle: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			sections: [...this.sectionsImport],
			selected: {},
			disableConfirm: true,
			collapse: { 0: true },
		};
	},
	computed: {
		confirmDisabled() {
			return this.sections.filter((section) => ['product_list'].includes(section.type) && (section.selected_qty || 0) !== section.qty).length > 0;
		},
	},
	watch: {
		sectionsImport: {
			handler() {
				this.sections = [...this.sectionsImport];
				this.selected = {}; // Reset selected items
				this.sections.forEach((section) => {
					section.selected_qty = 0; // Reset selected count
					if (section.type === 'dropdown' && typeof this.selected[this.sectionName(section)] === 'undefined') {
						this.selected = { ...this.selected, [this.sectionName(section)]: Object.keys(section.valid_products)[0] }; // Select the first item automatically
					} else if (section.type === 'product_list') {
						this.selected = { ...this.selected, [this.sectionName(section)]: { ...section.pre_selected ?? {} } }; // Initialize selected items
					}
				});
			},
			immediate: true,
		},
		selected: {
			handler() {
				this.sections = this.sections.map((section) => ({
					...section,
					selected_qty: this.getQtyBySection(section), // Update selected count
				}));
				this.$emit('update:selectableProducts', this.selected);
			},
			immediate: true,
		},
	},
	mounted() {
		EventBus.$on('showFreeProductsModal', () => {
			this.open();
		});
		EventBus.$on('hideFreeProductsModal', () => {
			this.close();
		});
	},
	beforeDestroy() {
		EventBus.$off('showFreeProductsModal');
		EventBus.$off('hideFreeProductsModal');
	},
	methods: {
		open() {
			this.$refs.freeProductsModal.show();
		},
		close() {
			this.$refs.freeProductsModal.hide();
			this.$emit('close');
		},
		confirm() {
			this.$emit('update:selectableProducts', this.selected);
			this.$refs.freeProductsModal.hide();
			this.$emit('confirm');
		},
		handleQtyChange(section, sku, qty) {
			if (typeof this.selected[this.sectionName(section)] === 'undefined') {
				this.selected[this.sectionName(section)] = {};
			}
			this.selected[this.sectionName(section)][sku] = qty;
			section.selected_qty = this.getQtyBySection(section); // Update selected count
			this.sections = [...this.sections]; // Reassign object to detect change
		},
		getQtyBySection(section) {
			if (section.type === 'product_list') {
				return Object.values(this.selected[this.sectionName(section)] ?? {}).reduce((previousValue, currentValue) => previousValue + currentValue, 0);
			}
			return 0;
		},
		toggleCollapse(index) {
			if (this.collapse[index]) {
				this.collapse[index] = false;
			} else {
				this.collapse = { ...this.collapse, [index]: true };
			}
		},
		sectionName(section) {
			if (typeof section.promo_code_name !== 'undefined' && section.promo_code_name !== null) {
				return section.promo_code_name;
			}
			return section.code_name;
		},
	},
};
</script>
<style>
/* Custom b-modal sizes https://github.com/bootstrap-vue/bootstrap-vue/issues/632#issuecomment-441719709 */
.modal-lg-custom {
	max-width: 880px !important;
	width: 880px !important;
}
.modal-content {
	width: 96% !important;
}
</style>
