import IsLoading from '@/components/Loading';
import QuantitySelect from '@/components/QuantitySelect';
import PossibleDiscounts from '@/components/PossibleDiscounts';
import WindowSizes from '@/mixins/WindowSizes';
import { PRODUCT_NON_CHANGEABLE_QTYS } from '@/settings/Products';
import {
	Cart as CartMessages, Store, Products, Purchase,
} from '@/translations';
import EventBus from '@/util/eventBus';
import AgencySite from '@/mixins/AgencySite';

export default {
	messages: [CartMessages, Store, Products, Purchase],
	components: { IsLoading, QuantitySelect, PossibleDiscounts },
	mixins: [WindowSizes, AgencySite],
	props: {
		cartItems: {
			type: Array,
			default: () => [],
		},
		total: {
			type: String,
			default: '',
		},
		totalVolume: {
			type: String,
			default: '',
		},
		totals: {
			type: Object,
			default: () => ({}),
		},
		points: {
			type: Object,
			default: () => ({}),
		},
		smallTextTotals: {
			type: Array,
			default: () => [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
		qtyImport: {
			type: Object,
			default: () => ({}),
		},
		exchange: {
			type: String,
			default: '',
		},
		applyFreeBottle: {
			type: Boolean,
			default: false,
		},
		freeBottleMessage: {
			type: String,
			default: '',
		},
		automaticDiscountPercentage: {
			type: [Number, String],
			default: 0,
		},
		cartRelatedItems: {
			type: Array,
			default: () => [],
		},
		possibleDiscountMessages: {
			type: Array,
			default: () => [],
		},
		promotions: {
			type: Array,
			default: () => [],
		},
		showMessages: {
			type: Boolean,
			default: false,
		},
		freeProductsInfo: {
			type: Array,
			default: () => [],
		},
		discountDetail: {
			type: Array,
			default: () => [],
		},
		freeShippingInfo: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			blackList: PRODUCT_NON_CHANGEABLE_QTYS,
			qty: {},
			alert: new this.$Alert(),
		};
	},
	computed: {
		totalsHasData() {
			return Object.keys(this.totals).length !== 0;
		},
		cartItemsHasData() {
			return this.cartItems.length !== 0;
		},
		cartRelatedItemsHasData() {
			return this.cartRelatedItems.length !== 0;
		},
	},
	watch: {
		qtyImport: {
			deep: true,
			handler(newVal) {
				this.qty = JSON.parse(JSON.stringify(newVal));
			},
			immediate: true,
		},
	},
	methods: {
		updateQuantity(qty, product) {
			this.$emit('update-quantity', qty, product);
			this.$emit('update-split-wallet');
		},
		removeProduct(e, product) {
			this.$emit('remove-product', product);
			this.$emit('update-split-wallet');
		},
		clickCheckPoints(e, isChecked) {
			let emit = 'use-points';
			if (isChecked) {
				emit = 'remove-points';
			} else {
				const options = {
					config: {
						icon: 'info',
						showCancelButton: false,
					},
				};
				const title = this.translate('pay_points_info_title');
				const message = this.translate('pay_points_info_message');
				this.alert.confirmation(title, message, options, true).then(() => {
				}).catch(() => {});
			}
			this.$emit(emit);
			this.$emit('update-split-wallet');
		},
		handleCheckoutRedirect(showPreModal) {
			if (showPreModal) {
				EventBus.$emit('show-pre-checkout-modal');
			} else {
				this.$router.push({ name: this.getRedirectName('Checkout') }).catch(() => {});
			}
		},
	},
};
