<template>
	<div id="getWidth">
		<form
			@submit.prevent="addressInfoSubmit"
			@keydown="clearError($event.target.name);"
			@change="clearError($event.target.name);">
			<b-row>
				<b-col
					v-if="!hideNameFields"
					cols="12"
					md="4">
					<input-text
						:id="'first_name'"
						:required="true"
						:label="translate('first_name')"
						:setter-value="form.first_name"
						:errors="errors[`${prefixErrors}first_name`]"
						type="text"
						autocomplete="given-name"
						@dataChanged="form.first_name = $event" />
				</b-col>
				<b-col
					v-if="!hideNameFields"
					cols="12"
					md="4">
					<input-text
						:id="'last_name'"
						:required="true"
						:label="translate('last_name')"
						:setter-value="form.last_name"
						:errors="errors[`${prefixErrors}last_name`]"
						type="text"
						autocomplete="family-name"
						@dataChanged="form.last_name = $event" />
				</b-col>
				<b-col
					v-if="address === 'shipping'"
					cols="12"
					md="4">
					<div class="form-group">
						<label for="mobile_number">
							{{ translate('mobile_number') }}
							<span class="text-danger">*</span>
						</label>
						<vue-tel-input
							id="mobile_number"
							ref="mobileNumberInput"
							v-model="form.mobile_number"
							:required="true"
							:label="translate('mobile_number')"
							:placeholder="translate('mobile_number')"
							:class="errors[`${prefixErrors}mobile_number`] ? 'is-invalid' : ''"
							v-bind="bindProps"
							type="text"
							name="mobile_number"
							class="form-control rounded-1"
							autocomplete="tel"
							@dataChanged="form.mobile_number = $event" />
						<template v-if="errors[`${prefixErrors}mobile_number`]">
							<span
								v-for="error in errors[`${prefixErrors}mobile_number`]"
								:key="error"
								class="invalid-feedback animated fadeIn"
								v-text="error" />
						</template>
					</div>
				</b-col>
				<template v-if="validatePersonalDocument">
					<b-col
						cols="12"
						md="4">
						<input-text
							:id="'personal_document_number'"
							:required="true"
							:label="translate(`nin_${countryCode.toLowerCase()}`)"
							:setter-value="form.personal_document_number"
							:errors="errors[`${prefixErrors}personal_document_number`]"
							type="text"
							@dataChanged="form.personal_document_number = $event" />
					</b-col>
					<b-col
						cols="12"
						md="4">
						<b-form-group
							class="media-files"
							label-for="personal_document_front_file">
							<template #label>
								{{ translate(`nin_${countryCode.toLowerCase()}_front`) }}
								<span class="text-danger">*</span>
							</template>
							<template v-if="currentPersonalDocumentFrontFile">
								<div class="d-flex align-items-center">
									<b-img
										style="max-width: 100px;"
										:src="currentPersonalDocumentFrontFile"
										:alt="translate(`nin_${countryCode.toLowerCase()}_front`)"
										class="img-fluid personal-document-image"
										@click="showImageViewer = true" />
									<image-viewer
										v-model="showImageViewer"
										:image-src="currentPersonalDocumentFrontFile" />
									<b-button
										variant="secondary"
										size="sm"
										class="ml-3"
										@click="form.personal_document_front_file = null; currentPersonalDocumentFrontFile = null">
										{{ translate('change') }}
									</b-button>
								</div>
							</template>
							<template v-else-if="form.personal_document_front_file">
								<div class="d-flex align-items-center">
									<div class="text-truncate">
										{{ form.personal_document_front_file.name }}
									</div>
									<b-button
										variant="danger"
										size="sm"
										class="ml-3"
										@click="form.personal_document_front_file = null">
										{{ translate('remove') }}
									</b-button>
								</div>
							</template>
							<template v-else>
								<b-form-file
									id="personal_document_front_file"
									v-model="form.personal_document_front_file"
									:class="hasError('personal_document_front_file') ? 'is-invalid' : ''"
									:placeholder="translate('no_file')"
									:browse-text="translate('browse')"
									:accept="IMAGES_EXT.map((ext) => `.${ext}`).join(', ')"
									class="text-truncate"
									type="file"
									@change="clearError(`${prefixErrors}personal_document_front_file`)" />
								<b-form-text id="personal_document_front_file">
									{{ translate('max', {size: '5'}) }} {{ translate('allowed_ext', {ext: IMAGES_EXT }) }}
								</b-form-text>
							</template>
							<template v-if="hasError('personal_document_front_file')">
								<span
									v-for="error in errors[`${prefixErrors}personal_document_front_file`]"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
					<b-col
						cols="12"
						md="4">
						<b-form-group
							class="media-files"
							label-for="personal_document_back_file">
							<template #label>
								{{ translate(`nin_${countryCode.toLowerCase()}_back`) }}
								<span class="text-danger">*</span>
							</template>
							<template v-if="currentPersonalDocumentBackFile">
								<div class="d-flex align-items-center">
									<b-img
										style="max-width: 100px;"
										:src="currentPersonalDocumentBackFile"
										:alt="translate(`nin_${countryCode.toLowerCase()}_back`)"
										class="img-fluid personal-document-image"
										@click="showImageViewer = true" />
									<image-viewer
										v-model="showImageViewer"
										:image-src="currentPersonalDocumentBackFile" />
									<b-button
										variant="secondary"
										size="sm"
										class="ml-3"
										@click="form.personal_document_back_file = null; currentPersonalDocumentBackFile = null">
										{{ translate('change') }}
									</b-button>
								</div>
							</template>
							<template v-else-if="form.personal_document_back_file">
								<div class="d-flex align-items-center">
									<div class="text-truncate">
										{{ form.personal_document_back_file.name }}
									</div>
									<b-button
										variant="danger"
										size="sm"
										class="ml-3"
										@click="form.personal_document_back_file = null">
										{{ translate('remove') }}
									</b-button>
								</div>
							</template>
							<template v-else>
								<b-form-file
									id="personal_document_back_file"
									v-model="form.personal_document_back_file"
									:class="hasError('personal_document_back_file') ? 'is-invalid' : ''"
									:placeholder="translate('no_file')"
									:browse-text="translate('browse')"
									:accept="IMAGES_EXT.map((ext) => `.${ext}`).join(', ')"
									class="text-truncate"
									type="file"
									@change="clearError(`${prefixErrors}personal_document_back_file`)" />
								<b-form-text id="personal_document_back_file">
									{{ translate('max', {size: '5'}) }} {{ translate('allowed_ext', {ext: IMAGES_EXT }) }}
								</b-form-text>
							</template>
							<template v-if="hasError('personal_document_back_file')">
								<span
									v-for="error in errors[`${prefixErrors}personal_document_back_file`]"
									:key="error"
									class="custom-invalid-feedback animated fadeIn"
									v-text="error" />
							</template>
						</b-form-group>
					</b-col>
				</template>
				<b-col
					cols="12"
					md="4">
					<input-text
						:id="'address'"
						:required="true"
						:disabled="form.is_pickup_address"
						:label="translate('address')"
						:setter-value="form.address"
						:errors="errors[`${prefixErrors}address`]"
						type="text"
						autocomplete="address-line1"
						@dataChanged="form.address = $event" />
				</b-col>
				<b-col
					v-if="!hideCountryField"
					cols="12"
					md="4">
					<div
						class="form-group">
						<label
							for="country_code"
							class="label">{{ translate('country') }}</label>
						<select
							id="country_code"
							v-model="form.country_code"
							:disabled="!allowChangeCountry || form.is_pickup_address"
							:class="hasError('country_code') ? 'is-invalid' : ''"
							name="country_code"
							:autocomplete="allowChangeCountry ? 'country-name' : 'off'"
							class="form-control">
							<template v-if="allowChangeCountry">
								<option value="">
									{{ translate('select_country') }}
								</option>
								<option
									v-for="country in countries"
									:key="country.id"
									:value="country.attributes.code">
									{{ translate(country.attributes.code.toLowerCase()) }}
								</option>
							</template>
							<template v-if="userCountry && !allowChangeCountry">
								<option :value="countryCode">
									{{ translate(countryCode.toLowerCase()) }}
								</option>
							</template>
						</select>
						<template v-if="hasError('country_code')">
							<span
								v-for="error in errors[`${prefixErrors}country_code`]"
								:key="error"
								class="custom-invalid-feedback animated fadeIn"
								v-text="error" />
						</template>
					</div>
				</b-col>
				<b-col
					cols="12"
					md="4">
					<div
						class="form-group">
						<label
							for="region_id"
							class="label">
							{{ translate('state') }}
							<span class="text-danger">*</span>
						</label>
						<select
							id="region_id"
							v-model="form.region_id"
							:disabled="form.is_pickup_address"
							:class="hasError('region_id') ? 'is-invalid' : ''"
							name="region_id"
							autocomplete="off"
							class="form-control"
							@change="handleRegionChange">
							<option value="">
								{{ translate('select_state') }}
							</option>
							<option
								v-for="state in states"
								:key="state.id"
								:value="state.id">
								{{ state.attributes.name }}
							</option>
						</select>
						<template v-if="hasError('region_id')">
							<span
								v-for="error in errors[`${prefixErrors}region_id`]"
								:key="error"
								class="custom-invalid-feedback animated fadeIn"
								v-text="error" />
						</template>
					</div>
				</b-col>
				<b-col
					cols="12"
					md="4">
					<div
						class="form-group">
						<label
							for="city"
							class="label">
							{{ translate('city') }}
							<span class="text-danger">*</span>
						</label>
						<select
							id="city"
							v-model="form.city"
							:class="hasError('city') ? 'is-invalid' : ''"
							name="city"
							autocomplete="off"
							class="form-control"
							:disabled="citiesLoading || form.is_pickup_address"
							@change="handleCityChange">
							<option value="">
								{{ translate('select_city') }}
							</option>
							<option
								v-for="city in cities"
								:key="city.id"
								:value="city.attributes.name">
								{{ city.attributes.name }}
							</option>
						</select>
						<template v-if="hasError('city')">
							<span
								v-for="error in errors[`${prefixErrors}city`]"
								:key="error"
								class="custom-invalid-feedback animated fadeIn"
								v-text="error" />
						</template>
					</div>
				</b-col>
				<b-col
					cols="12"
					md="4">
					<div
						class="form-group">
						<label
							for="address2"
							class="label">
							{{ translate('district') }}
							<span class="text-danger">*</span>
						</label>
						<select
							id="address2"
							v-model="form.address2"
							:class="hasError('address2') ? 'is-invalid' : ''"
							name="address2"
							autocomplete="off"
							class="form-control"
							:disabled="districtsLoading || citiesLoading || form.is_pickup_address"
							@change="handleDistrictChange">
							<option value="">
								{{ translate('select_district') }}
							</option>
							<option
								v-for="district in districts"
								:key="district.id"
								:value="district.attributes.name">
								{{ district.attributes.name }}
							</option>
						</select>
						<template v-if="hasError('address2')">
							<span
								v-for="error in errors[`${prefixErrors}address2`]"
								:key="error"
								class="custom-invalid-feedback animated fadeIn"
								v-text="error" />
						</template>
					</div>
				</b-col>
				<b-col
					cols="12"
					md="4">
					<input-text
						:id="'postcode'"
						:required="true"
						:label="translate('postcode')"
						:setter-value="form.postcode"
						:errors="errors[`${prefixErrors}postcode`]"
						type="text"
						autocomplete="postal-code"
						disabled
						@dataChanged="form.postcode = $event" />
				</b-col>
			</b-row>
			<b-row>
				<b-col
					v-if="displayOverrideToggle"
					:class="['xs', 'sm'].includes(windowWidth) ? 'mb-3' : ''"
					cols="12">
					<switch-toggle
						id="override_validation"
						v-model="form.override_validation"
						name="override_validation"
						variant="success"
						class="mr-2"
						pill />
					<label for="override_validation">
						{{ translate('override_validation') }}
						<span
							v-b-tooltip.hover
							:title="translate('override_validation_info')"
							class="text-muted">
							<i class="fas fa-lg fa-info-circle" />
						</span>
					</label>
				</b-col>
			</b-row>
			<b-row>
				<b-col
					v-if="displaySubmitButton || displayCancelButton || displayGobackButton"
					class="mt-3">
					<button
						v-if="displaySubmitButton"
						class="btn ml-2  btn-primary float-right"
						@click="$emit('on-submit', form)">
						{{ translate('submit') }}
					</button>
					<button
						v-if="displayCancelButton"
						class="btn btn-secondary b-4 float-right"
						@click="$emit('on-cancel')">
						{{ translate('cancel') }}
					</button>
					<button
						v-if="displayGobackButton"
						class="btn btn-secondary b-4 float-right"
						@click="$router.go(-1)">
						{{ translate('go_back') }}
					</button>
				</b-col>
			</b-row>
		</form>
	</div>
</template>
<script>
import { VueTelInput } from 'vue-tel-input';
import { ADDRESSES } from '@/settings/AddressBook';
import InputText from '@/components/InputText';
import SwitchToggle from '@/components/Switch';
import {
	Profile, Countries, Addresses, NIN,
} from '@/translations';
import ElementResolution from '@/mixins/ElementResolution';
import WindowSizes from '@/mixins/WindowSizes';
import EventBus from '@/util/eventBus';
import { IMAGES_EXT } from '@/settings/Media';
import ImageViewer from '@/components/ImageViewer/index.vue';
import Country from '@/util/Country';
import { VALID_COUNTRIES as availableCountries } from '@/settings/Country';

export default {
	name: 'AddressFormType3',
	messages: [Profile, Countries, Addresses, NIN],
	components: {
		ImageViewer, SwitchToggle, InputText, VueTelInput,
	},
	mixins: [ElementResolution, WindowSizes],
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		sameAsBilling: {
			type: Boolean,
			default: false,
		},
		errorsImport: {
			type: Object,
			default() {
				return {};
			},
		},
		prefixErrors: {
			type: String,
			default: '',
		},
		formImport: {
			type: Object,
			default() {
				return {};
			},
		},
		address: {
			type: String,
			default: ADDRESSES.default,
		},
		countries: {
			type: Array,
			required: true,
		},
		countryCodeImport: {
			type: String,
			default: '',
		},
		states: {
			type: Array,
			required: true,
		},
		userCountry: {
			type: Boolean,
			default: false,
		},
		displayCancelButton: {
			type: Boolean,
			default: false,
		},
		displaySubmitButton: {
			type: Boolean,
			default: true,
		},
		displayOverrideToggle: {
			type: Boolean,
			default: false,
		},
		displayGobackButton: {
			type: Boolean,
			default: true,
		},
		allowChangeCountry: {
			type: Boolean,
			default: false,
		},
		hideCountryField: {
			type: Boolean,
			default: false,
		},
		hideNameFields: {
			type: Boolean,
			default: false,
		},
		validatePersonalDocument: {
			type: Boolean,
			default: false,
		},
		currentPersonalDocumentFrontFileImport: {
			type: String,
			default: '',
		},
		currentPersonalDocumentBackFileImport: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			form: this.formImport,
			errors: this.errorsImport,
			countryCode: this.countryCodeImport,
			selectedCountryIso: '',
			IMAGES_EXT,
			currentPersonalDocumentFrontFile: this.currentPersonalDocumentFrontFileImport,
			currentPersonalDocumentBackFile: this.currentPersonalDocumentBackFileImport,
			showImageViewer: false,
			stateCities: new Country(),
			cityDistricts: new Country(),
			bindProps: {
				mode: 'international',
				inputOptions: {
					showDialCode: true,
				},
				disabledFetchingCountry: true,
			},
			availableCountries,
		};
	},
	computed: {
		citiesLoading() {
			return !!this.stateCities.data.loading;
		},
		districtsLoading() {
			return !!this.cityDistricts.data.loading;
		},
		cities() {
			try {
				return this.stateCities.data.response.data.data;
			} catch (e) {
				return [];
			}
		},
		districts() {
			try {
				return this.cityDistricts.data.response.data.data;
			} catch (e) {
				return [];
			}
		},
	},
	watch: {
		currentPersonalDocumentFrontFileImport(value) {
			this.currentPersonalDocumentFrontFile = value;
		},
		currentPersonalDocumentBackFileImport(value) {
			this.currentPersonalDocumentBackFile = value;
		},
		errorsImport(value) {
			this.errors = value;
		},
		'form.country_code': function onCountryChange(value) {
			if (value !== '' && value !== undefined) {
				this.$emit('country-change', value);
				this.form.region_id = this.formImport.region_id || '';
			}
		},
		'formImport.region_id': function onRegionChange() {
			this.loadInitialFormData();
		},
		formImport: {
			handler(value) {
				this.form = value;
			},
			deep: true,
		},
		countryCodeImport(value) {
			if (value !== '') {
				this.countryCode = value;
			} else {
				this.countryCode = this.$user.details().country ? this.$user.details().country.iso_code_2 : '';
			}
		},
	},
	created() {
		this.bindProps.defaultCountry = this.country;
		this.bindProps.onlyCountries = this.availableCountries;
	},
	mounted() {
		if (!this.userCountry) this.form.country_code = '';
		if (!this.countryCode) this.countryCode = this.$user.details().country ? this.$user.details().country.iso_code_2 : '';
		EventBus.$on('callAddressRequestData', () => {
			this.addressInfoSubmit();
		});
		this.loadInitialFormData();
	},
	methods: {
		loadInitialFormData() {
			if (this.form.region_id) {
				this.stateCities.getCities(this.form.region_id).then(() => {
					const cityId = this.cities.find((c) => c.attributes.name === this.form.city)?.id;
					if (cityId) {
						this.cityDistricts.getDistricts(cityId);
					}
				});
			}
		},
		addressInfoSubmit() {
			this.$emit('update:formImport', this.form);
		},
		hasError(field) {
			if (typeof this.errors[`${this.prefixErrors}${field}`] !== 'undefined') { return true; }
			return false;
		},
		clearError(field) {
			if (field) {
				delete this.errors[field];
				this.errors = { ...this.errors };
			}
		},
		handleRegionChange(event) {
			this.stateCities.getCities(event.target.value);
			this.form.city = '';
			this.form.address2 = '';
			this.form.postcode = '';
		},
		handleCityChange(event) {
			const cityName = event.target.value;
			this.form.address2 = '';
			this.form.postcode = '';
			if (cityName) {
				const cityId = this.cities.find((c) => c.attributes.name === event.target.value).id;
				this.cityDistricts.getDistricts(cityId);
			}
		},
		handleDistrictChange(event) {
			const districtName = event.target.value;
			this.form.postcode = '';
			this.clearError(`${this.prefixErrors}postcode`);
			if (districtName) {
				const district = this.districts.find((d) => d.attributes.name === event.target.value);
				this.form.postcode = district.attributes.postal_code;
			}
		},
	},
};
</script>

<style scoped>
.personal-document-image:hover {
	cursor: pointer;
	outline: 1px solid #df703d;
}
</style>
