export const EVENTS = [ // Shows events in All Tickets event filter
	'velovita_launch_all',
	'velovita_launch_gala',
	'velovita_xlr8_all',
	'velovita_xlr8_gala',
	'velovita_summit_ticket',
	'velovita_xlr8_2022_single',
	'velovita_xlr8_2022_single_extended',
	'velovita_xlr8_2022_companion',
	'velovita_xlr8_2022_companion_extended',
	'velovita_xlr8_2022_no_room',
	'velocity_tour_oct_29_2022',
	'velocity_tour_nov_05_2022',
	'velocity_tour_nov_12_2022',
	'velocity_tour_nov_18_2022',
	'velovita_incentive_trip_single_3_nights',
	'velovita_incentive_trip_single_4_nights',
	'velovita_incentive_trip_single_5_nights',
	'velovita_incentive_trip_double_3_nights',
	'velovita_incentive_trip_double_4_nights',
	'velovita_incentive_trip_double_5_nights',
	'velovita_3rd_anniversary_celebration',
	'velovita_leadership_summit_2023',
	'velovita_incentive_trip_punta_cana_single',
	'velovita_incentive_trip_punta_cana_double',
	'velovita_incentive_trip_punta_cana_upgrade_single',
	'velovita_incentive_trip_punta_cana_upgrade_double',
	'anniversary_celebration_2024',
];

export const AVAILABLE_EVENTS = [ // Shows events in new ticket screen
	'velovita_incentive_trip_punta_cana_single',
	'velovita_incentive_trip_punta_cana_double',
	'velovita_incentive_trip_punta_cana_upgrade_single',
	'velovita_incentive_trip_punta_cana_upgrade_double',
	'anniversary_celebration_2024',
];

export const TICKET_CODES = [ // Used to filter cart tickets
	'velovita_xlr8_ticket_all_included',
	'velovita_xlr8_ticket_gala',
	'velovita_summit_ticket',
	'velovita_xlr8_2022_ticket_single',
	'velovita_xlr8_2022_ticket_single_extended',
	'velovita_xlr8_2022_ticket_companion',
	'velovita_xlr8_2022_ticket_companion_extended',
	'velovita_xlr8_2022_ticket_no_room',
	'velovita_xlr8_2022_ticket_single_mp',
	'velovita_xlr8_2022_ticket_single_extended_mp',
	'velovita_xlr8_2022_ticket_companion_mp',
	'velovita_xlr8_2022_ticket_companion_extended_mp',
	'velovita_incentive_trip_single_3_nights',
	'velovita_incentive_trip_single_4_nights',
	'velovita_incentive_trip_single_5_nights',
	'velovita_incentive_trip_double_3_nights',
	'velovita_incentive_trip_double_4_nights',
	'velovita_incentive_trip_double_5_nights',
	'velovita_anniversary_celebration',
	'velovita_leadership_summit_2023',
	'velovita_incentive_trip_punta_cana_single',
	'velovita_incentive_trip_punta_cana_double',
	'velovita_incentive_trip_punta_cana_upgrade_single',
	'velovita_incentive_trip_punta_cana_upgrade_double',
	'anniversary_celebration_2024',
];
export default {};
