<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<b-row v-if="showTitle">
			<b-col>
				<div class="h4 my-2">
					{{ translate('sezzle') }}
				</div>
			</b-col>
		</b-row>
		<b-row class="row mb-3">
			<b-col>
				<b-alert
					:show="!paymentMethodEnabled"
					variant="danger"
					v-html="translate('sezzle_unavailable', {
						url: $router.resolve({ name: redirectTo }).href,
						min: typeof paymentMethodConditions.min !== 'undefined' ? paymentMethodConditions.maxFormatted : '',
						max: typeof paymentMethodConditions.max !== 'undefined' ? paymentMethodConditions.maxFormatted : '',
					})" />
				<b-alert
					v-if="paymentMethodEnabled"
					show
					variant="info"
					v-html="translate(sezzleDetails, { faqUrl: sezzleFaq })" />
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { SEZZLE_FAQ_URL } from '@/settings/Purchase';
import { PaymentMethods, PurchasePayment } from '@/translations';

export default {
	name: 'SezzlePayment',
	messages: [PaymentMethods, PurchasePayment],
	props: {
		showTitle: {
			type: Boolean,
			default: false,
		},
		paymentMethodConditions: {
			type: Object,
			default: () => ({}),
		},
		redirectTo: {
			type: String,
			default: 'Store',
		},
		sezzleDetails: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			sezzleFaq: SEZZLE_FAQ_URL,
		};
	},
	computed: {
		paymentMethodEnabled() {
			return this.paymentMethodConditions.isAvailable;
		},
	},
};
</script>
