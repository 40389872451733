import {
	GET_CREDIT_CARDS, GET_CREDIT_CARD, DELETE_CREDIT_CARD, CREATE_CREDIT_CARD, UPDATE_CREDIT_CARD, UPDATE_CREDIT_CARD_BILLING,
	SET_DEFAULT_CREDIT_CARD, SET_BACKUP_CARD, SORT_CREDIT_CARD, SET_BACKUP_CARD_AS_DEFAULT,
} from '@/config/endpoint';
import Req from './AxiosRequest';

class CreditCard {
	constructor() {
		const axios = new Req();
		this.data = axios;
		this.errors = axios.errors;
		this.loading = axios.loading;
	}

	clear() {
		this.data.clear();
	}

	getCreditCards(userID) {
		const { method, endpoint } = GET_CREDIT_CARDS;
		return this.data[method](endpoint(userID)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getCreditCard(userID, cardID) {
		const { method, endpoint } = GET_CREDIT_CARD;
		return this.data[method](endpoint(userID, cardID)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	deleteCreditCard(userID, cardID) {
		const { method, endpoint } = DELETE_CREDIT_CARD;
		return this.data[method](endpoint(userID, cardID)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	createCreditCard(userID, payload) {
		const { method, endpoint } = CREATE_CREDIT_CARD;
		return this.data[method](endpoint(userID), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateCreditCard(userID, cardID, payload) {
		const { method, endpoint } = UPDATE_CREDIT_CARD;
		return this.data[method](endpoint(userID, cardID), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateCreditCardBilling(userID, cardID, payload) {
		const { method, endpoint } = UPDATE_CREDIT_CARD_BILLING;
		return this.data[method](endpoint(userID, cardID), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	setDefaultCreditCard(userID, cardID) {
		const { method, endpoint } = SET_DEFAULT_CREDIT_CARD;
		return this.data[method](endpoint(userID, cardID)).then((response) => response).catch((error) => Promise.reject(error));
	}

	sortCreditCard(userID, cardID, payload) {
		const { method, endpoint } = SORT_CREDIT_CARD;
		return this.data[method](endpoint(userID, cardID), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	backupCreditCards(userID, payload) {
		const { method, endpoint } = SET_BACKUP_CARD;
		return this.data[method](endpoint(userID), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	setBackupCardAsDefault(userID) {
		const { method, endpoint } = SET_BACKUP_CARD_AS_DEFAULT;
		return this.data[method](endpoint(userID)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default CreditCard;
