<template>
	<div>
		<div
			v-if="cartItemsHasData && !loading"
			class="row no-gutters">
			<div class="col">
				<ul
					:class="twoColumns ? 'd-flex flex-row flex-wrap' : ''"
					class="list-group">
					<li
						v-for="(product, key) in products"
						:key="key"
						:class="{ 'pb-0': key === products.length - 1, 'w-50': twoColumns }"
						class="list-group-item px-0 border-0 rounded-0">
						<div class="row no-gutters">
							<div class="col-4">
								<img
									:class="['xs', 'sm', 'md'].includes(windowWidth) ? 'mw-100 align-middle' : ''"
									class="d-block m-auto"
									:src="product.image"
									alt="">
							</div>
							<div class="col-8">
								<div class="row no-gutters">
									<div class="col-11">
										<div class="h6 font-weight-bold text-primary">
											{{ translate(product.code_name) }}
											<span
												v-if="product.replaceable"
												v-b-tooltip.hover
												:title="translate('pick_flavors')"
												class="badge badge-green pointer">
												{{ translate('replaceable') }}
												<i class="fas fa-info-circle" />
											</span>
										</div>
										<div
											v-if="!isPack"
											class="row">
											<div class="col-4">
												<div class="font-weight-bold">
													{{ translate('subtotal') }}:
												</div>
											</div>
											<div class="col-8">
												<div class="text-right font-weight-light">
													{{ product.sub_total }}
												</div>
											</div>
										</div>
										<div
											v-if="!isPack && !isAgencySite"
											:class="!editingDisabled && !blackList.includes(product.sku) ? 'mb-1' : ''"
											class="row">
											<div class="col-4">
												<div class="font-weight-bold">
													{{ translate('bv') }}:
												</div>
											</div>
											<div class="col-8">
												<div class="text-right font-weight-light">
													{{ product.total_volume }}
												</div>
											</div>
										</div>
										<div
											v-if="!editingDisabled && !blackList.includes(product.sku)"
											class="row mb-1">
											<div class="col">
												<quantity-select
													:init-qty="qty[product.item_id]"
													:select-limit="10"
													@change="updateQuantity($event, product)" />
											</div>
										</div>
										<div
											v-else
											class="row">
											<div class="col-4 mb-1">
												<div class="font-weight-bold">
													{{ translate('qty_abbrev') }}:
													<span
														v-if="isPack"
														class="font-weight-light">
														{{ qty[product.item_id] }}
													</span>
												</div>
											</div>
											<div
												v-if="!isPack"
												class="col-8">
												<div class="text-right font-weight-light">
													{{ qty[product.item_id] }}
												</div>
											</div>
										</div>
										<div>
											<div
												v-if="!editingDisabled"
												class="row mt-1 mb-1">
												<div class="col-3">
													<a
														class="pointer font-weight-light text-small text-blue"
														@click="removeProduct($event, product)">
														{{ translate('remove') }}
													</a>
												</div>
												<div class="col-9 text-right">
													<span
														v-if="product.not_individual_purchase"
														v-b-tooltip.hover
														:title="translate('not_available_individually_message')"
														class="text-success text-small font-weight-light pointer">
														{{ translate('not_available_individually') }}
														<i class="fas fa-info-circle" />
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<is-loading
			v-show="!hideLoading"
			:loading-label="translate('loading')"
			:no-data-label="translate('empty_cart')"
			:loading="loading"
			:has-data="cartItemsHasData" />
	</div>
</template>
<script>
import CartDisplay from '@/mixins/CartDisplay';

export default {
	name: 'CartItems',
	mixins: [CartDisplay],
	props: {
		hideLoading: {
			type: Boolean,
			default: false,
		},
		editingDisabled: {
			type: Boolean,
			default: false,
		},
		isPack: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		twoColumns() {
			return !['xs', 'sm', 'md'].includes(this.windowWidth);
		},
		products() {
			return this.cartItems;
		},
	},
};
</script>
<style scoped>
	.text-blue {
		color: #13637f !important;
	}
</style>
