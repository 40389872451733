import { PURCHASE_LOGGED_STEPS as flowSteps } from '@/settings/Wizard';
import {
	Addresses, Countries, Dates, Events, Grids, Months, NIN, Packages, PaymentMethods, Products, Profile, Purchase as PurchaseMessages, Users, Validations,
} from '@/translations';
import Purchase from '@/util/Purchase';
import Steps from './Steps';
import store from '../store';

export default {
	messages: [Addresses, Countries, Dates, Events, Grids, Months, NIN, Packages, PaymentMethods, Products, Profile, PurchaseMessages, Users, Validations],
	mixins: [Steps],
	data() {
		return {
			validator: new Purchase(),
			preparing: false,
		};
	},
	computed: {
		steps() {
			const steps = [];
			Object.keys(flowSteps).forEach((stepName) => {
				if (flowSteps[stepName].innerSteps) {
					steps.push(...flowSteps[stepName].innerSteps);
				}
				steps.push({ [stepName]: flowSteps[stepName] });
			});
			return steps;
		},
		stepNames() {
			return this.steps.map((step) => Object.keys(step)[0]);
		},
		validating() {
			try {
				return this.validator.data.loading;
			} catch (error) {
				return false;
			}
		},
		validationErrors() {
			try {
				return this.validator.errors.errors;
			} catch (error) {
				return [];
			}
		},
		currentModule() {
			const [mod] = this.$route.matched;
			return mod.name;
		},
		currentValidation() {
			return this.getStepValidationByIndex(this.currentStep());
		},
		isLastStep() {
			return this.currentStep() === this.steps.length - 1;
		},
		initialInformation() {
			return JSON.parse(JSON.stringify(store.state.initialInformation));
		},
		loadingInitialInformation() {
			return !Object.keys(this.initialInformation).length;
		},
		completedSteps() {
			return store.state.completedSteps;
		},
	},
	methods: {
		confirmPurchase(payload) {
			return this.validator.confirmPurchase(payload)
				.then((response) => response)
				.catch((error) => Promise.reject(error));
		},
		updatePurchase(payload) {
			return this.validator.updatePurchaseInformation(payload)
				.then((response) => response)
				.catch((error) => Promise.reject(error));
		},
		clearValidationError(field) {
			this.validator.errors.clear(field);
		},
		saveStep(data, stepName) {
			this.storeStepInfo(data, stepName).then(() => this.$emit('saved')).catch(() => {});
		},
		storeStepInfo(data, stepName) {
			const payload = {};
			payload[stepName] = data;
			return store.dispatch('saveInfo', payload);
		},
		getStepInformation(stepName) {
			const currentStep = typeof stepName === 'undefined' ? this.$route.name : stepName;
			return JSON.parse(JSON.stringify(store.getters.getStepInformation(currentStep)));
		},
		saveCart(cartId) {
			return store.dispatch('saveCart', cartId);
		},
		removeInfo() {
			return store.dispatch('removeInfo').catch(() => {});
		},
		getStepByIndex(index) {
			return this.steps[index];
		},
		getStepNameByIndex(index) {
			return this.stepNames[index];
		},
		getStepValidationByIndex(index) {
			try {
				return this.getStepByIndex(index)[this.getStepNameByIndex(index)].validation;
			} catch (error) {
				return false;
			}
		},
		getStepValidationByName(stepName) {
			try {
				return this.getStepByName(stepName)[stepName].validation;
			} catch (error) {
				return false;
			}
		},
		getStepByName(stepName) {
			return this.steps.find((step) => Object.keys(step)[0] === stepName);
		},
		getStoredShippingAddressId() {
			const stepInfo = store.state.PurchaseShipping;
			if (Object.keys(stepInfo).length) {
				return stepInfo.shipping.shipping_address.address_id;
			}

			if (!this.loadingInitialInformation && !Array.isArray(this.initialInformation.shipping_address)) {
				return this.initialInformation.shipping_address.id;
			}

			return false;
		},
	},
};
