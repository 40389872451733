<template>
	<div>
		<b-card
			:bg-variant="background"
			no-body
			class="m-0">
			<b-card-header
				header-tag="header"
				class="p-0"
				role="tab">
				<b-row class="w-100 ml-auto mr-0">
					<div
						:class="{'d-none' : !showSelect}"
						class="align-self-center pl-2">
						<b-form-radio
							v-model="selected"
							:value="`${item.id}`"
							:name="`radio-btn_${internalId}`"
							class="credit-radio" />
					</div>
					<b-col class="px-0">
						<b-button
							v-b-toggle="`${prefix}${index}`"
							:class="showSelect ? 'p-2' : 'py-2 px-3'"
							block
							class="text-left font-weight-bold no-focus"
							href="#"
							variant="default">
							<img
								:key="index"
								:src="require(`@/assets/images/common/cc/card/${cardLogo(item.attributes.card_type)}.png`)"
								align="left"
								class="text-right mr-2">
							<p class="d-inline">
								{{ translate('last_digits') }} <b>{{ item.attributes.last_digits ? item.attributes.last_digits : 'XXXX' }} </b>
							</p>
							<span class="when-opened float-right"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
							<span class="when-closed float-right"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
							<br v-if="['xs', 'sm'].includes(windowWidth) && showDefault">
							<div
								v-if="showDefault"
								class="align-top float-right pr-1 mr-4"
								:class="['xs', 'sm'].includes(windowWidth) ? 'mb-2' : 'd-inline'">
								<b-button
									variant="link"
									class="p-0 ml-2 align-top"
									@click.stop="setDefault(item)">
									{{ translate('set_as_default') }}
								</b-button>
							</div>
						</b-button>
					</b-col>
				</b-row>
			</b-card-header>
			<b-collapse
				:id="`${prefix}${index}`"
				role="tabpanel">
				<b-card-body class="pb-2">
					<b-card-text>
						<ol class="p-0 mb-0">
							<b-row>
								<b-col class="mb-4 text-left">
									<b>{{ translate('name_on_card') }}</b>
									<p>{{ item.attributes.cardholder }}</p>
								</b-col>
								<b-col>
									<template v-if="addressEmpty(item.attributes.address)">
										<div class="mb-4 text-right">
											<b>{{ translate('billing_address') }}</b>
											<p class="no-bottom">
												{{ `${isEmpty(item.attributes.address, 'first_name')} ${isEmpty(item.attributes.address, 'last_name')}` }}
											</p>
											<p class="no-bottom">
												{{ isEmpty(item.attributes.address, 'address') }}
											</p>
											<p class="no-bottom">
												{{ isEmpty(item.attributes.address, 'address2') }}
											</p>
											<p class="no-bottom">
												{{ isEmpty(item.attributes.address, 'address3') }}
											</p>
											<p class="no-bottom">
												{{ isEmpty(item.attributes.address, 'address4') }}
											</p>
											<p class="no-bottom">
												{{ isEmpty(item.attributes.address, 'city') }}
											</p>
											<p
												v-if="item.attributes.address.region && Object.keys(item.attributes.address.region).length > 0 && item.attributes.address.region != null"
												class="no-bottom">
												{{ `${ item.attributes.address.region.name }, ${item.attributes.address.postcode }` }}
											</p>
											<p
												v-else
												class="no-bottom">
												{{ item.attributes.address.postcode }}
											</p>
											<p class="no-bottom">
												{{ translate(isEmpty(item.attributes.address, 'country')) }}
											</p>
											<p class="no-bottom">
												{{ isEmpty(item.attributes.address, 'telephone') }}
											</p>
										</div>
									</template>
									<template v-if="!addressEmpty(item.attributes.address)">
										<b-alert
											variant="danger"
											show>
											<i class="fas fa-exclamation-circle" />	{{ translate('no_billing_address') }}
										</b-alert>
									</template>
								</b-col>
							</b-row>
							<b-row :class="{'d-none' : showSelect}">
								<b-col>
									<b-button
										variant="link"
										class="text-left px-0 col-xs-12 col-md-auto py-0"
										@click="$emit('edit', item.id)">
										{{ translate('edit') }}
									</b-button>
									<template v-if="$can('credit_cards', 'delete')">
										<span class="mx-1 d-none d-md-inline-block">|</span>
										<b-button
											variant="link"
											class="text-left px-0 col-xs-12 col-md-auto py-0"
											@click="$emit('delete', item.id)">
											{{ translate('delete') }}
										</b-button>
									</template>
								</b-col>
							</b-row>
						</ol>
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>
	</div>
</template>

<script>
import { Profile, Countries, Common } from '@/translations';
import CreditCard from '@/mixins/CreditCard';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'CreditCardItem',
	messages: [Profile, Countries, Common],
	mixins: [CreditCard, WindowSizes],
	props: {
		item: {
			type: Object,
			default() {
				return {};
			},
		},
		internalId: {
			type: String,
			default: '',
		},
		index: {
			type: Number,
			default() {
				return 0;
			},
		},
		background: {
			type: String,
			default: () => '',
		},
		prefix: {
			type: String,
			default: () => '',
		},
		showSelect: {
			type: Boolean,
			default: false,
		},
		showDefault: {
			type: Boolean,
			default: false,
		},
		selectedImport: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			selected: 0,
			form: this.formImport,
			alert: new this.$Alert(),
		};
	},
	computed: {
		getImage() {
			let image = 'cc';
			if (this.item.attributes.card_type !== null && this.item.attributes.card_type !== '') {
				image = this.item.attributes.card_type;
			}
			return image;
		},
	},
	watch: {
		selectedImport(newVal) {
			this.selected = newVal;
		},
		selected(newVal) {
			this.$emit('change', newVal);
		},
	},
	mounted() {
		this.selected = this.selectedImport;
	},
	methods: {
		isEmpty(value, index) {
			try {
				if (index === 'country') return (value.country.code).toLowerCase();
				if (value[index] !== null && value[index] !== undefined) return value[index];
				return '';
			} catch (e) {
				return '';
			}
		},
		addressEmpty(value) {
			let valueSize = 0;
			if (value.length !== undefined) {
				valueSize = value.length;
			}
			if (value.length === undefined) {
				valueSize = Object.keys(value).length;
			}
			return !!valueSize;
		},
		setDefault(item) {
			const trans = {
				title: this.translate('change_default_title'),
				text: this.translate('change_default_text', { last_digits: item.attributes.last_digits }),
			};
			const options = {
				confirmButtonText: this.translate('confirm'),
				cancelButtonText: this.translate('cancel'),
			};
			this.alert.confirmation(trans.title, trans.text, options, false).then(() => {
				this.$emit('setDefault', item.id);
			});
		},
	},
};
</script>
