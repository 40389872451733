<template>
	<b-card-group
		:class="!showDisclaimer ? 'pt-2' : ''"
		deck>
		<is-loading
			:loading="loading"
			:loading-label="translate('loading')"
			:has-data="data.length > 0"
			:no-data-label="translate('no_credit_card')"
			:class="loading ? 'mx-auto' : 'w-100'">
			<template slot="no-data">
				<b-col>
					<b-alert
						variant="warning"
						show>
						<div class="row">
							<div class="col">
								<i class="fa fa-exclamation-circle" />	{{ showBackupDisclaimer ? translate('backup_card_disclaimer') : translate('no_credit_card') }}
							</div>
							<div class="col-md-4 col-sm-12">
								<b-button
									v-if="showBackupDisclaimer"
									class="pt-0 float-right"
									:class="['xs', 'sm'].includes(windowWidth) ? 'text-right' : 'align-top'"
									variant="link"
									@click="$emit('setFirstDefault')">
									{{ translate('set_first_backup_card_as_default') }}
								</b-button>
							</div>
						</div>
					</b-alert>
				</b-col>
			</template>
		</is-loading>
		<template v-if="data.length > 0 && !loading">
			<b-col v-if="showDisclaimer">
				<b-alert show>
					{{ translate('credit_card_disclaimer') }}
				</b-alert>
			</b-col>
			<b-col
				v-for="(item, index) in data"
				:key="index"
				class="mb-3 col-12">
				<credit-card-item
					:item="item"
					:internal-id="uniqueId"
					:index="index"
					:background="background"
					:show-select="showSelect"
					:show-default="showDefault"
					:selected-import="Number(localSelected)"
					:prefix="prefix"
					@change="handleChange($event)"
					@delete="deleteCard"
					@edit="$emit('edit', $event)"
					@setDefault="$emit('setDefault', $event)" />
			</b-col>
		</template>
	</b-card-group>
</template>

<script>
import { Profile, Countries } from '@/translations';
import CreditCardItem from '@/components/CreditCardItem';
import IsLoading from '@/components/Loading';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'CreditCard',
	messages: [Profile, Countries],
	components: { CreditCardItem, IsLoading },
	mixins: [WindowSizes],
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Array,
			required: true,
		},
		background: {
			type: String,
			default: () => '',
		},
		prefix: {
			type: String,
			default: () => '',
		},
		showSelect: {
			type: Boolean,
			default: false,
		},
		showDefault: {
			type: Boolean,
			default: false,
		},
		showBackupDisclaimer: {
			type: Boolean,
			default: false,
		},
		selected: {
			type: Number,
			default() {
				return 0;
			},
		},
		showDisclaimer: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			type: this.$user.details().type,
			paymentProcessor: process.env.VUE_APP_CC_PAYMENT_PROCESSOR,
			localSelected: this.selected,
			uniqueId: `component-${Math.random().toString(36).slice(2, 9)}`,
		};
	},
	watch: {
		selected(newVal) {
			this.localSelected = newVal;
		},
	},
	methods: {
		isEmpty(value, index) {
			try {
				if (index === 'country') return (value.country.code).toLowerCase();
				if (value[index] !== null && value[index] !== undefined) return value[index];
				return '';
			} catch (e) {
				return '';
			}
		},
		addressEmpty(value) {
			let valueSize = 0;
			if (value.length !== undefined) {
				valueSize = value.length;
			}
			if (value.length === undefined) {
				valueSize = Object.keys(value).length;
			}
			return !!valueSize;
		},
		deleteCard(creditCardID) {
			this.$emit('delete', creditCardID);
		},
		handleChange(selectedCard) {
			this.localSelected = selectedCard;
			this.$emit('change', selectedCard);
		},
	},
};
</script>
<style>
.no-bottom {
	margin-bottom: 0;
	padding: 0;
}
.bottom {
	position: absolute;
	bottom: 0;
	left: 0;
}
</style>
