export default {
	data() {
		return {
			flowName: '',
			amountToPay: 0,
			methodLimits: [],
			skipMinValidation: false,
		};
	},
	computed: {
		paymentMethodConditions() {
			const keys = Object.keys(this.methodLimits);
			const conditions = {};
			keys.forEach((key) => {
				const { limits } = this.methodLimits[key];

				if (limits === null) {
					conditions[key] = { isAvailable: true };
					return;
				}

				const subtotalMin = typeof limits.min !== 'undefined' ? this.amountToPay >= limits.min : true;
				const subtotalMax = typeof limits.max !== 'undefined' ? this.amountToPay <= limits.max : true;
				const isSubtotalWithinLimits = (subtotalMin || this.skipMinValidation) && subtotalMax;

				conditions[key] = {
					min: this.skipMinValidation ? 0 : limits.min,
					minFormatted: limits.minFormatted,
					max: limits.max,
					maxFormatted: limits.maxFormatted,
					isAvailable: isSubtotalWithinLimits,
				};
			});

			return conditions;
		},
	},
	methods: {
		setFlowName(name) {
			this.flowName = name;
		},
		setAmountToPay(amount) {
			this.amountToPay = amount;
		},
		setMethodLimits(methodLimits) {
			this.methodLimits = methodLimits;
		},
	},
};
