import { AVAILABLECC } from '@/settings/CreditCard';

export default {
	methods: {
		cardLogo(cardType) {
			if (cardType.length > 0 && AVAILABLECC.includes(cardType)) {
				return cardType;
			}
			return 'cc';
		},
	},
};
