<template>
	<div
		v-if="discountDetail.length"
		class="no-gutters justify-content-end"
		style="display: inline-block;">
		<div
			:id="`popover-discounts${id}`"
			class="pointer drb-pbv-popover-trigger text-decoration-none pointer"
			tabindex="0">
			<i class="fas fa-info-circle" />
		</div>
		<b-popover
			:target="`popover-discounts${id}`"
			triggers="hover"
			custom-class="drb-pbv-popover"
			boundary-padding="0"
			:placement="['xs','sm'].includes(windowWidth) ? 'bottom' : 'bottom'">
			<b-alert
				class="p-2 pb-0 rounded-0 mb-1 border-bottom"
				show
				variant="secondary-darker">
				<div class="row no-gutters">
					<div class="col">
						<h6
							class="mb-0">
							{{ translate("discount_detail") }}
						</h6>
					</div>
				</div>
			</b-alert>
			<div class="m-1 pb-1 pt-0 mt-0">
				<template>
					<div class="pbv-popover">
						<div class="table-responsive mb-0">
							<table class="table text-nowrap">
								<tbody>
									<tr
										v-for="(item, index) in discountDetail"
										:key="index">
										<!--<td class="p-2 text-center">
											{{ index + 1 }}
										</td>-->
										<td class="p-2 text-left">
											{{ item.name }}
										</td>
										<td
											class="p-2 align-middle text-right text-discount">
											{{ item.amount }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</template>
			</div>
		</b-popover>
	</div>
</template>
<script>
import {
	Grids,
} from '@/translations';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'DiscountDetail',
	messages: [Grids],
	mixins: [WindowSizes],
	props: {
		discountDetail: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			// eslint-disable-next-line no-underscore-dangle
			id: this._uid,
		};
	},
};
</script>
<style scoped>
.drb-pbv-popover-trigger:focus {
	outline: none;
}
.drb-pbv-popover {
	border-radius: 0;
	max-width: 500px;
}
.drb-pbv-popover > .popover-body {
	padding: 0;
	max-width: 375px;
}
.pbv-popover {
	max-height: 200px;
	overflow-x: auto;
	overflow-y: auto;
}
.pbv-popover::-webkit-scrollbar {
    width: 5px;
}
.pbv-popover::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}
.pbv-popover::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear,height .2s linear,width .2s ease-in-out,border-radius .2s ease-in-out;
    right: 2px;
    width: 6px
}
table.text-nowrap tbody tr:first-child td {
	border-top: none;
	margin-top: 0;
	padding-top: 0;
}
</style>
