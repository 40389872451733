<template>
	<div>
		<b-alert
			v-if="promotions.length > 0"
			show
			variant="green"
			class="px-2 pb-0 m-0 mb-2">
			<div
				class="text-left m-0 p-0">
				<p
					class="text-center m-0"
					style="font-weight: bold; -webkit-text-stroke: 0.2px;">
					{{ translate('zlem_promo_title') }}
				</p>
				<div
					class="px-4 pb-2"
					style="background-color: transparent">
					<div
						v-for="message of promotions"
						:key="message"
						style="font-size: small; text-align: justify">
						<i class="fas fa-gift" />
						{{ message }}
					</div>
				</div>
			</div>
		</b-alert>
		<b-alert
			v-if="showFreeProductsSection"
			show
			variant="green"
			class="px-2 pb-0 m-0 mb-2">
			<div
				class="text-left m-0 p-0">
				<p
					class="text-center m-0"
					style="font-weight: bold; -webkit-text-stroke: 0.2px;">
					{{ translate('zlem_promo_title') }}
				</p>
				<div
					class="px-4 pb-2"
					style="background-color: transparent">
					<div
						v-for="(info, index) of visibleFreeProductsInfo"
						:key="index"
						style="font-size: small; text-align: justify">
						<i class="fas fa-gift" />
						{{ translate(`${sectionName(info.selectable_products)}${info.selectable_products.qty > 1 ? '_plural' : ''}`, { qty: info.selectable_products.qty }) }}
					</div>
				</div>
			</div>
		</b-alert>
		<b-alert
			v-if="possibleDiscountMessages.length > 0"
			show
			class="px-2 pb-0 m-0">
			<div
				class="text-left m-0 p-0">
				<p
					class="text-center m-0"
					style="font-weight: bold; -webkit-text-stroke: 0.2px;">
					{{ translate('discounts_title') }}
				</p>
				<ul
					class="px-4"
					style="background-color: transparent">
					<li
						v-for="message of possibleDiscountMessages"
						:key="message"
						style="font-size: small; text-align: justify">
						{{ message }}
					</li>
				</ul>
			</div>
		</b-alert>
	</div>
</template>
<script>
import { Cart } from '@/translations';

export default {
	name: 'PossibleDiscounts',
	messages: [Cart],
	props: {
		possibleDiscountMessages: {
			type: Array,
			default: () => [],
		},
		promotions: {
			type: Array,
			default: () => [],
		},
		freeProductsInfo: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		showFreeProductsSection() {
			return this.visibleFreeProductsInfo.length > 0 && !['PSStore', 'PSProductDetails', 'PSCart', 'PSPurchaseConfirmation'].includes(this.$route.name);
		},
		visibleFreeProductsInfo() {
			return this.freeProductsInfo.filter((info) => info.selectable_products.qty > 0);
		},
	},
	methods: {
		sectionName(section) {
			if (typeof section.promo_code_name !== 'undefined' && section.promo_code_name !== null) {
				return section.promo_code_name;
			}
			return section.code_name;
		},
	},
};
</script>
