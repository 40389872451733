<template>
	<b-alert
		show
		class="mb-0 pb-0">
		<div>
			<div class="d-flex justify-content-between">
				<h5>
					<i
						v-if="loading"
						class="fa fa-fw fa-spinner fa-pulse" />
					<i
						v-else
						class="fas fa-credit-card text-small pb-1" />
					<b> {{ translate('split_payment') }} </b>
				</h5>
				<div>
					<switch-toggle
						v-model="splitPayment"
						variant="success"
						pill
						no-margin
						class="mb-0"
						@change="$emit('change', $event)" />
				</div>
			</div>
			<p>{{ translate('split_payment_desc') }}</p>
		</div>
	</b-alert>
</template>
<script>
import Switch from '@/components/Switch';
import { SplitPayment } from '@/translations';

export default {
	name: 'SplitPayment',
	messages: [SplitPayment],
	components: {
		'switch-toggle': Switch,
	},
	props: {
		active: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			splitPayment: this.active,
		};
	},
	watch: {
		active() {
			this.splitPayment = this.active;
		},
	},
};
</script>
