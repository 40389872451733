<template>
	<li class="list-group-item h-100 p-0">
		<div
			:class="showCollapseIndicators ? 'pointer' : ''"
			@click="$emit('clicked', collapse)">
			<div
				v-if="!editing"
				:class="{
					'border-bottom': !collapse || editing,
					'p-1': !(hideControls || hideEdit),
					'p-2': (hideControls || hideEdit)
				}"
				class="row m-0 pl-2 alert-secondary-darker">
				<div class="col my-auto align-middle">
					<div class="h5 m-0">
						<i
							v-if="showCollapseIndicators"
							:class="collapse ? 'fas fa-chevron-right' : 'fas fa-chevron-down'" />
						{{ title }}
					</div>
				</div>
				<div
					v-if="!hideControls && !hideEdit"
					class="col-auto align-middle text-center">
					<b-button
						variant="outline-primary"
						class="btn-sm my-auto ml-auto"
						@click="toggleEdit">
						<!-- slot. If the edit-slot is empty, just show 'Edit'. -->
						<slot name="edit" />
						<template v-if="!$slots.edit">
							{{ translate('edit') }}
						</template>
					</b-button>
				</div>
			</div>
			<div
				v-else
				class="row no-gutters">
				<div class="col p-0">
					<div
						:class="activeColor ? `bg-${activeColor}` : 'bg-primary'"
						class="alert w-100 m-0 text-white border-0 p-2 rounded-0">
						<div class="col my-auto align-middle">
							<div class="h5 m-0">
								<i
									v-if="showCollapseIndicators"
									:class="collapse ? 'fas fa-chevron-right' : 'fas fa-chevron-down'" />
								{{ title }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="!collapse"
			:class="noInnerSpacing ? '' : 'm-2'"
			class="row">
			<div class="col">
				<slot />
			</div>
		</div>
		<div
			v-if="editing && !hideControls && !collapse"
			class="row mx-2 mb-3">
			<div class="col">
				<div class="float-right">
					<button
						v-if="!hideCancel"
						class="btn btn-sm btn-secondary mr-1"
						:disabled="disableCancel"
						@click="cancel">
						{{ cancelText ? cancelText: translate('cancel') }}
					</button>
					<button
						v-if="!hideSave"
						class="btn btn-sm btn-primary"
						:disabled="disableSave"
						@click="save">
						{{ saveText ? saveText: translate('save') }}
					</button>
				</div>
			</div>
		</div>
	</li>
</template>
<script>
export default {
	name: 'FlowInfoSection',
	props: {
		title: {
			type: String,
			default: '',
		},
		activeColor: {
			type: String,
			default: '',
		},
		collapse: {
			type: Boolean,
			default: false,
		},
		disableEdit: {
			type: Boolean,
			default: false,
		},
		disableCancel: {
			type: Boolean,
			default: false,
		},
		disableSave: {
			type: Boolean,
			default: false,
		},
		hideEdit: {
			type: Boolean,
			default: false,
		},
		hideCancel: {
			type: Boolean,
			default: false,
		},
		hideSave: {
			type: Boolean,
			default: false,
		},
		hideControls: {
			type: Boolean,
			default: false,
		},
		initEdit: {
			type: Boolean,
			default: false,
		},
		noInnerSpacing: {
			type: Boolean,
			default: false,
		},
		overrideEdit: {
			type: Boolean,
			default: false,
		},
		cancelText: {
			type: String,
			default: '',
		},
		saveText: {
			type: String,
			default: '',
		},
		showCollapseIndicators: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			editing: false,
		};
	},
	watch: {
		initEdit(newVal) {
			this.editing = newVal;
		},
	},
	mounted() {
		this.editing = this.initEdit;
	},
	methods: {
		toggleEdit() {
			if (!this.overrideEdit) {
				this.editing = !this.editing;
			}
			this.$emit('toggled', this.editing);
		},
		cancel() {
			this.toggleEdit();
			this.$emit('cancel');
		},
		save() {
			this.toggleEdit();
			this.$emit('save');
		},
	},
};
</script>
