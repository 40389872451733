<template>
	<div :class="containerClass ? containerClass : 'quantity-select-container'">
		<template v-if="qty >= selectLimit && !isDisabled">
			<template v-if="stackedLayout">
				<div class="row no-gutters w-100 mb-1">
					<div class="col-12">
						<input
							v-model="inputQty"
							class="w-100 h-100 center-dropdown">
					</div>
				</div>
				<div class="row no-gutters w-100">
					<div class="col-12">
						<b-button
							class="w-100 h-100 p-0"
							variant="primary"
							@click="handleQtyInput">
							{{ translate('update') }}
						</b-button>
					</div>
				</div>
			</template>
			<div
				v-else
				:class="`row no-gutters ${subContainerClass}`">
				<div
					style="max-width: 60px"
					class="col-6 pr-1 my-1">
					<input
						v-model="inputQty"
						class="w-100 h-100 center-dropdown">
				</div>
				<div
					style="max-width: 90px"
					class="col pl-1 my-1">
					<b-button
						class="w-100 h-100 p-0"
						variant="primary"
						@click="handleQtyInput">
						{{ translate('update') }}
					</b-button>
				</div>
			</div>
		</template>
		<select
			v-if="qty < selectLimit || isDisabled"
			v-model="qty"
			:disabled="isDisabled"
			style="width: 100px"
			class="pl-2 h-100 custom-select custom-select-sm text-dark"
			@change="handleQtySelect">
			<option
				v-if="includeZero"
				:value="0"
				class="font-weight-light">
				{{ translate('qty_abbrev') }}: 0
			</option>
			<option
				v-for="item in selectLimit - 1"
				:key="item"
				:value="item"
				class="font-weight-light">
				{{ translate('qty_abbrev') }}: {{ item }}
			</option>
			<option
				:value="selectLimit"
				class="font-weight-light">
				{{ translate('qty_abbrev') }}: {{ selectLimit }}+
			</option>
		</select>
	</div>
</template>
<script>
export default {
	name: 'QuantityInput',
	props: {
		isDisabled: {
			type: Boolean,
			default: false,
		},
		initQty: {
			type: Number,
			default: 0,
		},
		min: {
			type: Number,
			default: 0,
		},
		max: {
			type: Number,
			default: 0,
		},
		selectLimit: {
			type: Number,
			default: 10,
		},
		includeZero: {
			type: Boolean,
			default: false,
		},
		stackedLayout: {
			type: Boolean,
			default: false,
		},
		containerClass: {
			type: String,
			default: '',
		},
		subContainerClass: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			inputQty: 0,
			qty: 0,
		};
	},
	watch: {
		initQty(newVal) {
			this.qty = newVal;
		},
		qty(newVal) {
			this.inputQty = newVal;
		},
	},
	mounted() {
		this.qty = this.initQty;
	},
	methods: {
		handleQtyInput() {
			let qty = Math.floor(Number(this.inputQty));

			qty = this.min > qty ? this.min : qty;
			if (this.max) {
				qty = qty > this.max ? this.max : qty;
			}

			this.qty = qty;
			this.inputQty = qty;
			this.$emit('change', this.qty);
		},
		handleQtySelect() {
			if (this.qty < this.selectLimit) {
				this.$emit('change', this.qty);
			}
		},
	},
};
</script>
<style scoped>
	.quantity-select-container {
		max-width: 100%;
	}
	select {
		background-color: #f9f9f9 !important;
		/* background: #bf5279 !important; */
	}
</style>
