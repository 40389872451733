<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="row mb-2">
		<template v-if="!!Object.keys(address).length && !loadingOverride">
			<b-col
				lg="12"
				xl="12">
				<p class="mb-0">
					<b>{{ address.first_name }} {{ address.last_name }}</b>
				</p>
				<p class="mb-0">
					<i class="fas fa-mobile-alt mr-0" />
					{{ address.telephone }}
				</p>
				<p class="mb-0">
					{{ address.address }}
				</p>
				<p class="mb-0">
					{{ address.address2 }}
				</p>
				<p class="mb-0">
					{{ address.address3 }}
				</p>
				<p class="mb-0">
					{{ address.address4 }}
				</p>
				<p class="mb-0">
					{{ address.city }}, {{ address.region.name }}, {{ address.postcode }}
				</p>
				<p class="mb-0">
					{{ translate(address.country.code.toLowerCase()) }}
				</p>
				<template v-if="address.is_pickup_address">
					<span class="badge badge-primary">
						{{ translate('pickup_at_office') }}
					</span>
				</template>
				<div
					v-else-if="Object.keys(shippingMethodInfo).length && shippingMethodInfo.name.length"
					class="mt-1 mb-0">
					<img
						v-if="shippingMethodInfo.image"
						:src="require(`@/assets/images/common/shipping/carriers/${shippingMethodInfo.image}`)"
						class="my-auto border"
						style="width: 45px; height: 30px;">
					<p class="text-muted d-inline">
						<span
							v-if="shippingMethodInfo.name"
							class="ml-1">
							{{ translate(shippingMethodInfo.name) }}
						</span>
						<small
							v-if="deliveryDays.length"
							class="font-italic">
							<br>{{ translate('estimated_date') }}{{ translate(deliveryDays) }}
						</small>
					</p>
					<br>
					<small
						v-if="shippingNotice.length"
						class="font-italic"
						v-html="translate(shippingNotice)" />
				</div>
			</b-col>
			<b-col
				v-if="showShippingAlert && !address.is_pickup_address"
				cols="12"
				:class="['lg', 'xl'].includes(windowWidth) ? '' : 'mt-2'">
				<div>
					<b-alert
						show
						variant="warning"
						class="overflow-hidden pt-2">
						<b-row no-gutters>
							<b-col v-html="translate('shippint_alert_lites')" />
						</b-row>
					</b-alert>
				</div>
			</b-col>
		</template>
		<div
			v-else
			class="col-12 fade-in text-center d-flex justify-content-center align-items-center">
			<div class="col-12">
				<div class="h2">
					<i class="fa fa-fw fa-spinner fa-pulse" />
					<h4 class="mt-3">
						{{ translate('loading') }}
					</h4>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {
	SHIPPING_METHOD_CODES as shippingMethods,
} from '@/settings/Shipping';
import Country from '@/util/Country';
import Addresses from '../../mixins/Addresses';
import CommonMix from '../../mixins/Common';
import WindowSizes from '@/mixins/WindowSizes';
import store from '../../store';

export default {
	name: 'ShippingOverview',
	mixins: [Addresses, CommonMix, WindowSizes],
	props: {
		loadingOverride: {
			type: Boolean,
			default: false,
		},
		shippingMethodImport: {
			type: String,
			default: '',
		},
		overrideShippingMethod: {
			type: Boolean,
			default: false,
		},
		showShippingAlert: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			stateData: new Country(),
			addressId: 0,
			shippingMethod: {
				shipping_method: '',
			},
			shippingMethods,
			checked: false,
		};
	},
	computed: {
		shippingMethodInfo() {
			try {
				const storedShippingMethod = this.shippingMethod.shipping_method;
				const { shippingMethodImport } = this;
				const loadedShippingMethodName = this.overrideShippingMethod ? shippingMethodImport : storedShippingMethod;

				if (loadedShippingMethodName === '') {
					return {};
				}

				const shippingMethod = { name: loadedShippingMethodName, ...this.shippingMethods[loadedShippingMethodName] };
				return shippingMethod;
			} catch (error) {
				return {};
			}
		},
		deliveryDays() {
			try {
				const deliveryDays = this.shippingMethodInfo.delivery_days;
				return deliveryDays[this.country] ? deliveryDays[this.country] : [];
			} catch (error) {
				return [];
			}
		},
		shippingNotice() {
			try {
				const shippingNotice = this.shippingMethodInfo.shipping_notice;
				return shippingNotice[this.country] ? shippingNotice[this.country] : shippingNotice.default;
			} catch (error) {
				return [];
			}
		},
		address() {
			const foundAddress = this.addressData.find((address) => +address.id === +this.addressId);
			return foundAddress ? foundAddress.attributes : {};
		},
	},
	watch: {
		initialInformation() {
			if (!store.getters.getCompletedSteps().includes('PurchaseShipping')) {
				this.loadFromInitialInformation();
			}
		},
		address() {
			this.checked = this.address.is_gift_address ?? false;
		},
	},
	created() {
		this.addressType = 'shipping';
		if (store.getters.getCompletedSteps().includes('PurchaseShipping')) {
			const stepInfo = this.getStepInformation('PurchaseShipping');
			const { shipping } = stepInfo;
			if (typeof shipping !== 'undefined') {
				this.shippingMethod.shipping_method = shipping.shipping_method;
				this.addressId = shipping.shipping_address.address_id;
			}
		} else {
			this.loadFromInitialInformation();
		}
	},
	methods: {
		loadFromInitialInformation() {
			if (!Object.keys(this.initialInformation).length) {
				return null;
			}

			if (Array.isArray(this.initialInformation.shipping_address)) {
				this.$emit('noData');
			} else {
				this.shippingMethod.shipping_method = this.initialInformation.shipping_method;
				this.addressId = this.initialInformation.shipping_address.id;
			}
			return null;
		},
	},
};
</script>
