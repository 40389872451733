import {
	GET_CUSTOM_CARTS, GET_CUSTOM_CART, CREATE_CART, CREATE_CUSTOM_CART, DELETE_CUSTOM_CART, GET_CUSTOM_CART_PRODUCTS,
	RESTORE_CUSTOM_CART,
} from '@/config/endpoint';
import { apiFilters } from '@/config/axios';
import Req from './AxiosRequest';

class CustomCarts {
	constructor() {
		this.data = new Req(apiFilters);
		this.errors = this.data.errors;
		this.loading = this.data.loading;
		this.pagination = this.data.pagination;
		this.options = {};
	}

	getCustomCarts(options) {
		const { method, endpoint } = GET_CUSTOM_CARTS;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getCustomCart() {
		const customCartUuid = localStorage.getItem('custom_cart_uuid');
		const { method, endpoint } = GET_CUSTOM_CART;
		return this.data[method](endpoint(customCartUuid)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	createCart(country) {
		const options = { guest_cart: 1, country_code: country, is_custom_cart: 1 };
		const { method, endpoint } = CREATE_CART;
		return this.data[method](endpoint, options).then((response) => response).catch((error) => {
			this.data.loading = false;
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	createCustomCart(payload) {
		const { method, endpoint } = CREATE_CUSTOM_CART;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => Promise.reject(error));
	}

	getCustomCartProducts(cart) {
		const { method, endpoint } = GET_CUSTOM_CART_PRODUCTS;
		return this.data[method](endpoint(cart)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	deleteCustomCart(customCartId) {
		const { method, endpoint } = DELETE_CUSTOM_CART;
		return this.data[method](endpoint(customCartId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	restoreCustomCart(customCartUuid) {
		const { method, endpoint } = RESTORE_CUSTOM_CART;
		return this.data[method](endpoint(customCartUuid)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default CustomCarts;
