<template>
	<div
		class="row no-gutters"
		style="height: 2.325em;">
		<div
			:class="stackedLayout ? 'col-6' : 'col-7'"
			class="d-flex p-0 h-100 align-items-center">
			<span
				:class="stackedLayout ? '' : 'ml-4'"
				class="text-medium">
				{{ translate(configuration) }}
			</span>
		</div>
		<div
			:class="stackedLayout ? 'col-6' : 'col-5'"
			class="d-flex w-100 align-items-center">
			<quantity-select
				:init-qty="initQty"
				:select-limit="10"
				:max="99"
				include-zero
				:container-class="`w-100 ${stackedLayout ? 'text-right' : ''}`"
				sub-container-class="justify-content-end w-100"
				@change="$emit('change', $event)" />
		</div>
	</div>
</template>
<script>
import QuantitySelect from '@/components/QuantitySelect';
import { Products, Purchase } from '@/translations';

export default {
	name: 'ProductConfiguration',
	messages: [Products, Purchase],
	components: { QuantitySelect },
	props: {
		configuration: {
			type: String,
			required: true,
		},
		initQty: {
			type: Number,
			required: true,
		},
		stackedLayout: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
