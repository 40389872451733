import store from '../store';

export default {
	methods: {
		initializeStepNumber(stepNumber) {
			store.dispatch('saveStepNumber', stepNumber);
		},
		nextStep() {
			this.goToStep(this.currentStep() + 1);
		},
		goToStep(step) {
			store.dispatch('saveStepNumber', step);
		},
		currentStep() {
			return store.getters.getCurrentStep();
		},
	},
};
