import {
	GET_ADDRESSES,
	GET_ADDRESS,
	SAVE_ADDRESS,
	DELETE_ADDRESS,
	UPDATE_ADDRESS,
	UPDATE_RECURRING_ADDRESS,
	ADDRESS_SUGGESTION,
	GET_ADDRESS_DOCUMENT,
} from '@/config/endpoint';
import Req from './AxiosRequest';

class AddressBook {
	constructor() {
		const axios = new Req();
		this.data = axios;
		this.errors = axios.errors;
		this.loading = axios.loading;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	getAddresses(userID, options = {}) {
		const { method, endpoint } = GET_ADDRESSES;
		return this.data[method](endpoint(userID), options, true).then((response) => response).catch((error) => {
			this.errors.record(error); return Promise.reject(error);
		});
	}

	getAddress(userID, addressID) {
		const { method, endpoint } = GET_ADDRESS;
		return this.data[method](endpoint(userID, addressID)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAddressDocument(userID, addressID, side) {
		const { method, endpoint } = GET_ADDRESS_DOCUMENT;
		return this.data.getBlobData({ method, url: endpoint(userID, addressID, side) }).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getSuggestion(payload) {
		const { method, endpoint } = ADDRESS_SUGGESTION;

		this.options.data = payload;
		this.options.url = endpoint;
		this.options.method = method;

		return this.data.getBodyData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	saveAddress(userID, payload) {
		const { method, endpoint } = SAVE_ADDRESS;

		this.options.data = payload;
		this.options.config = { headers: { 'Content-Type': 'multipart/form-data' } };
		this.options.url = endpoint(userID);
		this.options.method = method;
		return this.data.postFormData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateAddress(userID, addressID, payload) {
		const { method, endpoint } = UPDATE_ADDRESS;

		this.options.data = payload;
		this.options.config = { headers: { 'Content-Type': 'multipart/form-data' } };
		this.options.url = endpoint(userID, addressID);
		this.options.method = method;

		return this.data.postFormData(this.options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	deleteAddress(userID, addressID) {
		const { method, endpoint } = DELETE_ADDRESS;
		return this.data[method](endpoint(userID, addressID)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateRecurringAddress(userID, addressID, payload) {
		const { method, endpoint } = UPDATE_RECURRING_ADDRESS;
		return this.data[method](endpoint(userID, addressID), payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default AddressBook;
