<template>
	<div class="row mb-2">
		<div
			:class="stackedLayout ? '' : ''"
			class="col-12">
			<div
				class="row"
				:style="stackedLayout ? '' : 'height: 2.325em'">
				<div
					v-if="stackedLayout"
					class="col-12 d-flex p-0">
					<div class="my-auto">
						<span class="font-weight-bolder">{{ translate(codeName) }}</span><br>
					</div>
				</div>
				<div
					v-else
					class="col-12 d-flex p-0 h-100 align-items-center">
					<div class="h-100 mr-2">
						<img
							:src="thumbnail"
							class="h-100">
					</div>
					<span class="font-weight-bolder">
						{{ translate(codeName) }}
					</span>
				</div>
			</div>
			<div
				:class="stackedLayout ? 'mb-0' : ''"
				class="row">
				<div class="col-12">
					<div class="row">
						<div
							:class="stackedLayout ? 'pl-2' : ''"
							class="col-12">
							<slot />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { Products, Purchase } from '@/translations';

export default {
	name: 'ConfigurableProduct',
	messages: [Products, Purchase],
	props: {
		codeName: {
			type: String,
			required: true,
		},
		configName: {
			type: String,
			required: true,
		},
		sku: {
			type: String,
			required: true,
		},
		thumbnail: {
			type: String,
			default: '',
		},
		stackedLayout: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
