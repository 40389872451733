<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div
			v-if="!hasCoupon"
			class="list-group-item h-auto py-3 rounded-0">
			<h5>
				<i class="fas fa-ticket-alt text-small pb-1" />
				<b> {{ translate('coupon_code_label') }} </b>
			</h5>
			<div class="row no-gutters my-2 d-flex justify-content-end">
				<div
					id="coupon-input-text"
					class="col">
					<input-text
						:id="'coupon_code'"
						:placeholder="translate('enter_coupon_code')"
						:setter-value="couponCode"
						:disabled="loading"
						class="mb-0"
						type="text"
						:errors="errors.coupon_code"
						@dataChanged="handleChange" />
				</div>
				<div class="pl-1 col-auto">
					<button
						:disabled="Object.keys(errors).length > 0 || couponCode.length === 0 || loading"
						type="submit"
						class="btn btn-primary w-100"
						@click="apply()">
						<template v-if="!loading">
							{{ translate('apply') }}
						</template>
						<template v-else>
							<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
						</template>
					</button>
				</div>
			</div>
		</div>
		<div
			v-else
			class="alert alert-green mb-0">
			<ul class="fa-ul mb-0">
				<li>
					<span class="fa-li h5 m-0">
						<i class="fas fa-ticket-alt pb-1 mr-2" />
					</span>
					<span
						class="h5 m-0"
						v-html="translate('coupon_to_be_applied', { coupon_code: cartCoupon.code })" />
					<span
						v-b-tooltip.hover.html
						:title="translate(`${cartCoupon.type}_description`)"
						class="text-small mb-1 h5 ml-2">
						<i class="fas fa-lg fa-info-circle" />
					</span>
				</li>
				<li class="list-unstyled mt-1">
					<a
						v-if="!loading"
						class="pointer font-weight-light text-medium text-blue"
						@click="remove">
						<i class="fa fa-trash text-medium mr-1" /> {{ translate('remove_coupon') }}
					</a>
					<span
						v-else
						class="pointer font-weight-light text-medium">
						<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
					</span>
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
import InputText from '@/components/InputText';
import { Coupons } from '@/translations';

export default {
	name: 'CouponInput',
	messages: [Coupons],
	components: {
		InputText,
	},
	props: {
		cartCoupon: {
			type: Object,
			default: () => ({}),
		},
		loading: {
			type: Boolean,
			default: false,
		},
		errorsImport: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			errors: this.errorsImport,
			couponCode: '',
		};
	},
	computed: {
		hasCoupon() {
			return !!Object.keys(this.cartCoupon).length;
		},
	},
	watch: {
		errorsImport: {
			deep: true,
			handler(newVal) {
				this.errors = newVal;
			},
		},
	},
	methods: {
		apply() {
			this.$emit('submit', this.couponCode);
			this.$emit('update-split-wallet');
		},
		handleChange(code) {
			this.couponCode = code.toUpperCase();
			this.$emit('change');
		},
		remove() {
			this.$emit('remove');
			this.$emit('update-split-wallet');
		},
	},
};
</script>
<style>
	/* Not scoped due to it needing to affect the style of a child component, so using an ID instead */
	#coupon-input-text .form-group {
		margin-bottom: 0;
	}
</style>
<style scoped>
	.text-blue {
		color: #13637f !important;
	}
</style>
