<!-- eslint-disable vue/no-v-html -->
<template>
	<b-modal
		ref="sameAddressAsCardStatementModal"
		header-class="mx-auto w-100 bg-dark text-white"
		no-close-on-backdrop
		centered>
		<template v-slot:modal-header>
			<h5
				v-if="cardType"
				class="modal-title text-white">
				{{ translate('confirm_card') }}
				<img
					:src="require(`@/assets/images/common/cc/card/${cardLogo(cardType)}.png`)"
					class="text-right"
					alt="Credit Card Logo">
				{{ lastDigits }}
				<template v-if="cardType2 && lastDigits2">
					{{ translate('and') }}
					<img
						:src="require(`@/assets/images/common/cc/card/${cardLogo(cardType2)}.png`)"
						class="text-right"
						alt="Second Credit Card Logo">
					{{ lastDigits2 }}
				</template>
			</h5>
		</template>
		<template v-slot:default>
			<div v-if="Object.keys(address).length > 0">
				<div>
					<ul style="padding-left: 10px">
						<!-- <li>
							<span>{{ translate('issued_in_country') }}</span> <b>{{ translate(countryToCheck.toLowerCase()) }}</b>.
						</li> -->
						<li>
							<p v-html="translate('identical_address')" />
							<div class="address-container">
								<p class="mb-0">
									<b>{{ address.first_name }} {{ address.last_name }}</b>
								</p>
								<p class="mb-0">
									{{ address.address }}
								</p>
								<p class="mb-0">
									{{ address.address2 }}
								</p>
								<p class="mb-0">
									{{ address.city }}, {{ address.region.name }}, {{ address.postcode }}
								</p>
								<p class="mb-0">
									{{ translate(address.country.code.toLowerCase()) }}
								</p>
							</div>
						</li>
					</ul>
				</div>
				<p v-html="translate('important_note')" />
			</div>
		</template>
		<template v-slot:modal-footer>
			<b-button
				variant="light"
				size="md"
				@click="cancel">
				{{ translate('go_back') }}
			</b-button>
			<b-button
				variant="primary"
				size="md"
				@click="confirm">
				{{ translate('correct_information') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import CreditCard from '@/mixins/CreditCard';
import { Countries, SameAddressAsCardStatement } from '@/translations';

export default {
	name: 'SameAddressAsCardStatementModal',
	messages: [Countries, SameAddressAsCardStatement],
	mixins: [CreditCard],
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		countryToCheck: {
			type: String,
			required: true,
		},
		cardType: {
			type: String,
			default: '',
		},
		lastDigits: {
			type: String,
			default: '',
		},
		cardType2: {
			type: String,
			default: '',
		},
		lastDigits2: {
			type: String,
			default: '',
		},
		address: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		show: {
			get() {
				return this.value;
			},
			set(newValue) {
				this.$emit('input', newValue);
			},
		},
	},
	watch: {
		show: {
			handler(show) {
				if (show) {
					this.$refs.sameAddressAsCardStatementModal.show();
				} else {
					this.$refs.sameAddressAsCardStatementModal.hide();
				}
			},
			immediate: false,
		},
	},
	methods: {
		confirm() {
			this.show = false;
			this.$emit('confirm');
		},
		cancel() {
			this.show = false;
			this.$emit('cancel');
		},
	},
};
</script>

<style scoped>
.address-container {
	margin-left: -10px;
	margin-top: 15px;
	padding: 10px 15px;
	background-color: #f2f2f2;
	border-radius: 8px;
}
</style>
<style>
.modal-footer {
	justify-content: end !important;
}
</style>
