import {
	REPLICATED_SITE,
	UPDATE_REPLICATED_SITE,
	REPLICATED_SITE_NAV,
	GET_AGENCY_ACTIVE_PROMO,
	GET_AGENCY_PROMOTIONS,
	SCHEDULE_AGENCY_PROMOTION,
	DISABLE_AGENCY_PROMOTION,
	GET_AGENCY_REPLICATED_INFO,
	GET_AGENCY_PROMO_TYPES,
	SAVE_PROMO_EMAIL,
} from '@/config/endpoint';
import Req from './AxiosRequest';

const newPromoAxios = new Req();
class ReplicateSite {
	constructor() {
		const axios = new Req();
		this.data = axios;
		this.errors = axios.errors;
		this.loading = axios.loading;
		this.options = {};
	}

	clear() {
		this.data.clear();
	}

	getReplicatedSite(replicatedId) {
		const { method, endpoint } = REPLICATED_SITE;
		return this.data[method](endpoint(replicatedId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAgencyReplicatedLinks() {
		const { method, endpoint } = GET_AGENCY_REPLICATED_INFO;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAgencyPromotions(options) {
		const { method, endpoint } = GET_AGENCY_PROMOTIONS;
		return this.data[method](endpoint, options, true).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getReplicatedSiteNav(replicatedId) {
		const { method, endpoint } = REPLICATED_SITE_NAV;
		return this.data[method](endpoint(replicatedId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	updateReplicatedSite(replicatedId, options) {
		const { method, endpoint } = UPDATE_REPLICATED_SITE;
		return this.data[method](endpoint(replicatedId), options).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getPromoTypes() {
		const { method, endpoint } = GET_AGENCY_PROMO_TYPES;
		return this.data[method](endpoint).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	getAgencyActivePromo(options) {
		const { method, endpoint } = GET_AGENCY_ACTIVE_PROMO;
		let params = '';
		if (options) {
			params = Req.setFilters(options);
		}
		return this.data[method](endpoint(params)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	scheduleAgencyPromo(options) {
		const { endpoint, method } = SCHEDULE_AGENCY_PROMOTION;
		this.options.data = options;
		this.options.config = { headers: { 'Content-Type': 'multipart/form-data' } };
		this.options.url = endpoint;
		this.options.method = method;
		return newPromoAxios.postFormData(this.options).then((response) => {
			this.data = response;
			this.pagination = newPromoAxios.pagination;
			return this.data;
		}).catch((errors) => Promise.reject(errors)).finally(() => {
			this.loading = false;
			return null;
		});

		// const { method, endpoint } = SCHEDULE_AGENCY_PROMOTION;
		// return this.data[method](endpoint, options).then((response) => response).catch((error) => {
		// 	this.errors.record(error);
		// 	return Promise.reject(error);
		// });
	}

	deactivateAgencyPromo(promoId) {
		const { method, endpoint } = DISABLE_AGENCY_PROMOTION;
		return this.data[method](endpoint(promoId)).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}

	savePromoEmail(payload) {
		const { method, endpoint } = SAVE_PROMO_EMAIL;
		return this.data[method](endpoint, payload).then((response) => response).catch((error) => {
			this.errors.record(error);
			return Promise.reject(error);
		});
	}
}

export default ReplicateSite;
