var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mb-2"},[(!_vm.loadingOverride)?_c('div',{staticClass:"col"},[_c('div',{staticClass:"h5"},[(_vm.walletSplit)?_c('span',[_vm._v(_vm._s(_vm.translate('cashondelivery'))+" + ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.translate(_vm.paymentMethod.name))+" ")]),(_vm.billing.country_code !== '' && !_vm.loadingState)?[_c('b-row',[_c('b-col',[_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v(_vm._s(_vm.billing.first_name)+" "+_vm._s(_vm.billing.last_name))])]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.billing.address)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.billing.address2)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.billing.address3)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.billing.address4)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.billing.city)+", "+_vm._s(_vm.billing.region_name)+", "+_vm._s(_vm.billing.postcode)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.translate(_vm.billing.country_code.toLowerCase()))+" ")])])],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{class:{
						'col-auto': !_vm.walletSplit && !_vm.isSplitPayment && !['xs', 'sm'].includes(_vm.windowWidth),
						'col-12': ['xs', 'sm'].includes(_vm.windowWidth),
						'mt-2': ['xs', 'sm'].includes(_vm.windowWidth)
					},attrs:{"order":Number(_vm.walletSplit)}},[_c('p',{staticClass:"mb-0"},[(_vm.walletSplit)?_c('b',[_vm._v(_vm._s(_vm.translate(_vm.paymentMethod.name)))]):_vm._e(),(_vm.isSplitPayment)?_c('b',[_vm._v(_vm._s(_vm.translate('main_credit_card')))]):_vm._e()]),(_vm.isCreditCard)?_c('p',{staticClass:"mb-0 d-inline"},[_c('img',{staticClass:"my-auto mr-1",staticStyle:{"width":"45px","height":"30px"},attrs:{"src":require(("@/assets/images/common/cc/logos/" + _vm.cardLogo + ".png")),"alt":"Card Logo"}}),_c('span',{staticClass:"text-muted"},[_vm._v(" ****"+_vm._s(_vm.paymentMethod.last_digits.length > 0 ? _vm.paymentMethod.last_digits : ''))])]):_vm._e(),_c('p',{staticClass:"mb-0 d-inline",class:{ 'ml-2': _vm.isCreditCard }},[(_vm.walletSplit)?_c('b',[_vm._v(_vm._s(_vm.translate(_vm.paymentMethodConditions.code.toLowerCase(), { amount: (_vm.cartTotal - _vm.walletSplitAmount).toFixed(_vm.paymentMethodConditions.decimals) })))]):_vm._e(),(_vm.isSplitPayment)?_c('b',[_vm._v(_vm._s(_vm.displayedSplitAmount1))]):_vm._e()])]),[(!_vm.isSplitPayment && _vm.canSplitWithWallet)?_c('b-col',{style:(!_vm.walletSplit && !_vm.isCreditCard ? 'margin-top: -35px' : ''),attrs:{"order":Number(!_vm.walletSplit)}},[_c('div',{staticClass:"d-flex align-items-center h-100",class:{ 'justify-content-end': !_vm.walletSplit, 'justify-content-start': _vm.walletSplit }},[(!_vm.walletSplit)?_c('span',{staticClass:"text-primary btn-link pointer",on:{"click":function($event){return _vm.$emit('walletSplit')}}},[_vm._v(" "+_vm._s(_vm.translate('split_between_two_payment_methods', { first: _vm.translate('cashondelivery'), second: _vm.translate('other_method') }))+" ")]):_c('b',[_vm._v(" "+_vm._s(_vm.translate('cashondelivery'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.translate(_vm.paymentMethodConditions.code.toLowerCase(), { amount: (_vm.walletSplitAmount).toFixed(_vm.paymentMethodConditions.decimals) }))+" ")])])]):_vm._e(),(_vm.isSplitPayment)?_c('b-col',{staticClass:"col-12 mb-2",class:{
							'col-md-6': !['xs', 'sm'].includes(_vm.windowWidth),
							'mt-2': ['xs', 'sm'].includes(_vm.windowWidth)
						}},[_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v(_vm._s(_vm.translate('additional_credit_card')))])]),_c('p',{staticClass:"mb-0 d-inline"},[_c('img',{staticClass:"my-auto mr-1",staticStyle:{"width":"45px","height":"30px"},attrs:{"src":require(("@/assets/images/common/cc/logos/" + _vm.cardLogo2 + ".png")),"alt":"Card Logo"}}),_c('span',{staticClass:"text-muted"},[_vm._v(" ****"+_vm._s(_vm.paymentMethod.last_digits_2.length > 0 ? _vm.paymentMethod.last_digits_2 : ''))])]),_c('p',{staticClass:"mb-0 d-inline ml-2"},[_c('b',[_vm._v(_vm._s(_vm.displayedSplitAmount2))])])]):_vm._e()]],2)]:_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-12 fade-in text-center d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"h2"},[_c('i',{staticClass:"fa fa-fw fa-spinner fa-pulse"}),_c('h4',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.translate('loading'))+" ")])])])])])],2):_c('div',{staticClass:"col-12 fade-in text-center d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"h2"},[_c('i',{staticClass:"fa fa-fw fa-spinner fa-pulse"}),_c('h4',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.translate('loading'))+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }