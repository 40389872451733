<template>
	<div>
		<div
			v-if="loading"
			:class="size === 'bg' ? 'tableAlert' : 'tableAlertSm' "
			class="alert text-center">
			<i
				:class="size === 'bg' ? 'fa-5x' : 'fa-3x'"
				class="fa fa-fw fa-spinner fa-pulse" />
			<h4 class="mt-3">
				{{ loadingLabel }}
			</h4>
		</div>
		<template v-if="!loading && !hasData">
			<slot name="no-data">
				<div
					:class="size === 'bg' ? 'tableAlert' : 'tableAlertSm' "
					class="alert text-center">
					<i
						:class="{
							'fa-5x': size === 'bg',
							'fa-3x': size !== 'bg',
							[iconClass]: true,
						}" />
					<h4 class="mt-3">
						{{ noDataLabel }}
					</h4>
				</div>
			</slot>
		</template>
	</div>
</template>
<script>

export default {
	name: 'LoadingData',
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		hasData: {
			type: Boolean,
			default: true,
		},
		size: {
			type: String,
			default: 'sm',
		},
		noDataLabel: {
			type: String,
			default: "We couldn't find any records",
		},
		loadingLabel: {
			type: String,
			default: 'Loading',
		},
		iconClass: {
			type: String,
			default: 'fa fa-exclamation-circle',
		},
	},
	data() {
		return {
		};
	},
};
</script>
<style>
	.tableAlert{
		padding-top: 50px;
		padding-bottom: 50px;
		margin-bottom: 0;
		min-height: 310px;
	}

	.tableSm{
		padding-top: 25px;
		padding-bottom: 25px;
		margin-bottom: 0;
		min-height: 155px;
	}
</style>
