<template>
	<div>
		<div
			:class="'text-dark alert alert-' + alertClass + ' mb-3'">
			<h5
				class="text-center font-weight-bold">
				{{ translate('points_title') }}
			</h5>
			<div
				class="font-weight-normal">
				<template v-for="(specificPoint, key) in points">
					<div
						v-if="['total_points_format', 'points_used_format'].includes(key)"
						:key="key">
						<div class="row pb-2">
							<div class="col-4 text-nowrap">
								<div
									class="text-dark font-weight-light">
									{{ translate(key) }}
								</div>
							</div>
							<div class="col-8">
								<div
									class="text-right text-dark font-weight-light">
									{{ specificPoint }}
								</div>
							</div>
						</div>
					</div>
				</template>
				<div class="row">
					<div class="col">
						<div class="border-top">
							<div class="row pt-2">
								<div class="col-6">
									<div class="h6 text-nowrap">
										{{ translate('points_available_format') }}
									</div>
								</div>
								<div class="col-6">
									<div
										:class="{
											'text-red': points.points_available < 0,
										}"
										class="h6 text-right">
										{{ points.points_available_format }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row pt-2">
					<div class="col text-center">
						<div
							class="form-check"
							@click="handleClickButton($event, points.points_used)">
							<input
								id="tokenPayCart"
								:checked="points.points_used"
								name="tokenPayCart"
								class="form-check-input float-right"
								type="checkbox">
							<label
								for="tokenPayCart"
								class="h6 pointer text-blue ml-1">
								{{ translate('pay_points') }}
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import CartDisplay from '@/mixins/CartDisplay';
import { Cart } from '@/translations';

export default {
	name: 'CartPoints',
	mixins: [CartDisplay],
	messages: [Cart],
	props: {
		alertClass: {
			type: String,
			default: 'secondary',
		},
	},
	data() {
		return {
			isChecked: false,
		};
	},
	methods: {
		handleClickButton(event, pointsUsed) {
			if (this.points.has_bundle_discount && !pointsUsed) {
				const trans = {
					title: this.translate('bundle_points_title'),
					text: this.translate('bundle_points_text'),
				};
				const options = {
					confirmButtonText: this.translate('confirm'),
					cancelButtonText: this.translate('cancel'),
				};
				this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
					this.clickCheckPoints(event, pointsUsed);
				});
			} else {
				this.clickCheckPoints(event, pointsUsed);
			}
		},
	},
};
</script>
<style scoped>
	.text-red {
		color: #d02a36 !important;
	}
	.text-blue {
		color: #13637f !important;
	}
</style>
