import cookie from 'vue-cookie';
import moment from 'moment/moment';
import {
	AGENCY_REPLICATED_SITE_NAME,
	AGENCY_REPLICATED_SITE_PATHS,
	AGENCY_REPLICATED_SITE_ROUTES_PREFIX,
	AGENCY_COUNTDOWN_MINUTES,
} from '@/settings/Replicated';
import { formatPathName } from '@/util/AgencySite';
import ReplicatedSite from '@/util/ReplicatedSite';
// import { COUNTDOWN_TIMEZONE } from '@/settings/Countdown';
import Countdown from '@/mixins/Countdown';

export default {
	mixins: [Countdown],
	data() {
		return {
			agencyReplicatedData: new ReplicatedSite(),
			promoStartsAt: null,
			promoEndsAt: null,
		};
	},
	computed: {
		isAgencySite() {
			return this.$route.name.search(AGENCY_REPLICATED_SITE_ROUTES_PREFIX) >= 0;
		},
		agencyActivePromo() {
			try {
				return this.agencyReplicatedData.data.response.data.data;
			} catch (e) {
				return null;
			}
		},
		activePromoExists() {
			try {
				const { attributes } = this.agencyActivePromo;
				return typeof attributes !== 'undefined' && attributes !== null;
			} catch (e) {
				return false;
			}
		},
		agencyDiscountPercentage() {
			try {
				const { discount } = this.agencyActivePromo.attributes;
				return discount;
			} catch (e) {
				return 0;
			}
		},
		agencyBannerDesktopImage() {
			try {
				const { desktop_image: desktopImage } = this.agencyActivePromo.attributes;
				return desktopImage;
			} catch (e) {
				return '';
			}
		},
		agencyBannerMobileImage() {
			try {
				const { mobile_image: mobileImage } = this.agencyActivePromo.attributes;
				return mobileImage;
			} catch (e) {
				return '';
			}
		},
		agencyDiscountEndsAt() {
			try {
				const now = moment.tz(this.timezone).add(AGENCY_COUNTDOWN_MINUTES, 'minutes').format('YYYY-MM-DD HH:mm:ss');
				return now;
			} catch (e) {
				return 0;
			}
		},
		agencyDiscountStartsAt() {
			try {
				const { from_date: from } = this.agencyActivePromo.attributes;
				return from;
			} catch (e) {
				return 0;
			}
		},
		agencyPromoSwitch() {
			return this.$replicated.replicatedCheckPromo();
		},
		agencyDiscountCode() {
			return this.$replicated.replicatedDiscountCode();
		},
		promoSeconds() {
			return this.seconds.toString();
		},
		promoHours() {
			return this.hours.toString();
		},
		promoMinutes() {
			return this.minutes.toString();
		},
		agencyPath() {
			const pathParts = this.$route.path.split('/');
			const agencyPath = pathParts[1];
			const isAgencyPath = Object.values(AGENCY_REPLICATED_SITE_PATHS).includes(agencyPath);
			if (isAgencyPath) {
				return agencyPath;
			}
			return null;
		},
		storeIn() {
			return process.env.VUE_APP_AUTH_STORE;
		},
	},
	watch: {
		isAgencySite: {
			handler(agencySite) {
				if (agencySite && this.$user.auth()) {
					this.$router.push({ name: 'Store' }).catch(() => {});
					this.$replicated.siteOff();
					this.$replicated.siteReplicatedSponsorOff();
					this.$replicated.siteReplicatedPageOff();
					this.deleteCountdownEndDate();
				}
			},
			immediate: true,
		},
	},
	methods: {
		updateManyAgencyProductsPrice(products) {
			if (this.isAgencySite && this.agencyDiscountPercentage > 0) {
				return products.map((product) => {
					product.attributes.promo_price_amount = this.roundToTwoDecimalPlaces(product.attributes.price_amount * (1 - this.agencyDiscountPercentage / 100));
					product.attributes.promo_price = `$${product.attributes.promo_price_amount.toFixed(2)} USD`;
					return product;
				});
			}
			return products;
		},
		roundToTwoDecimalPlaces(num) {
			return Math.round((num + Number.EPSILON) * 100) / 100;
		},
		updateSingleAgencyProductPrice(product) {
			if (this.isAgencySite && this.agencyDiscountPercentage > 0) {
				product.attributes.promo_price_amount = this.roundToTwoDecimalPlaces(product.attributes.price_amount * (1 - this.agencyDiscountPercentage / 100));
				product.attributes.promo_price = `$${product.attributes.promo_price_amount.toFixed(2)} USD`;
				if (product.attributes.children && !Array.isArray(product.attributes.children)) {
					const newChildren = Object.entries(product.attributes.children).map(([sku, attributes]) => {
						attributes.promo_price_amount = this.roundToTwoDecimalPlaces(attributes.price_amount * (1 - this.agencyDiscountPercentage / 100));
						attributes.promo_price = `$${attributes.promo_price_amount.toFixed(2)} USD`;
						return [
							sku,
							attributes,
						];
					});
					product.attributes.children = Object.fromEntries(newChildren);
				}
				return product;
			}
			return product;
		},
		getRedirectName(redirect) {
			return this.isAgencySite ? formatPathName(this.agencyPath, redirect) : redirect;
		},
		getRedirectNameByReplicated(redirect) {
			if (!this.$user.auth()) {
				return this.$replicated.replicatedId() === AGENCY_REPLICATED_SITE_NAME ? formatPathName(this.agencyPath, redirect) : redirect;
			}
			return redirect;
		},
		getAvailableDiscounts(fromDeals, discountCode) {
			let options;
			if (fromDeals || discountCode) {
				options = { deals: fromDeals ? 1 : 0, discount_code: discountCode };
			}
			this.agencyReplicatedData.getAgencyActivePromo(options);
		},
		startAgencyPromoTimer() {
			if (!this.agencyDiscountEndsAt) {
				return;
			}
			const savedDate = this.getCountdownEndDate();
			let endDate = savedDate;
			let changeDate = typeof savedDate === 'undefined' || savedDate === null;
			if (!changeDate) {
				changeDate = moment(savedDate).isBefore(moment());
			}

			if (changeDate) {
				const newEndDate = moment.tz(this.timezone).add(AGENCY_COUNTDOWN_MINUTES, 'minutes');
				const dateToSave = newEndDate.valueOf();
				endDate = newEndDate.format('YYYY-MM-DD HH:mm:ss');
				this.setCountdownEndDate(dateToSave);
			}

			// this.timezone = COUNTDOWN_TIMEZONE;
			this.timerId = 'agencyPromoTimer';
			this.useSeconds = true;
			this.interval = 1000;
			this.countdown(endDate, this.agencyDiscountStartsAt);
		},
		setCountdownEndDate(value) {
			if (this.storeIn === 'COOKIE') {
				cookie.set('promoCountdown', value);
			}
			if (this.storeIn === 'LOCALSTORAGE') {
				localStorage.setItem('promoCountdown', value);
			}
		},
		getCountdownEndDate() {
			let savedItem = null;
			if (this.storeIn === 'COOKIE') {
				savedItem = cookie.get('promoCountdown');
			}
			if (this.storeIn === 'LOCALSTORAGE') {
				savedItem = localStorage.getItem('promoCountdown');
			}

			return savedItem ? moment(savedItem, 'x') : null;
		},
		deleteCountdownEndDate() {
			if (this.storeIn === 'COOKIE') {
				cookie.delete('promoCountdown');
			}
			if (this.storeIn === 'LOCALSTORAGE') {
				localStorage.removeItem('promoCountdown');
			}
		},
	},
};
