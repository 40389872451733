<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		:class="{
			'card': card,
			'can-checkout pointer': showCheckoutButton,
		}"
		class="upgrade-progress-container w-100 mb-0 p-0"
		@click="handleClick">
		<div
			:class="card ? 'card-body p-1' : ''"
			class="m-0">
			<div class="text-dark">
				<div class="row no-gutters">
					<template v-if="compact">
						<div :class="showCheckoutButton ? 'col-5' : 'col-6'">
							<div class="row no-gutters align-items-center h-100 py-1">
								<div class="col">
									<div class="row mb-1">
										<div class="col w-100">
											<b-progress
												:max="targetPackage.volume_required"
												variant="primary"
												height="1.5em"
												animated
												striped>
												<b-progress-bar :value="currentVolume" />
											</b-progress>
										</div>
									</div>
									<div class="row no-gutters justify-content-center w-100">
										<div class="col-auto">
											<span class="text-dark text-medium">
												{{ currentVolume }} / {{ targetPackage.volume_required }} {{ translate('bv_added') }}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div :class="showCheckoutButton ? 'col-7' : 'col-6'">
							<div class="row no-gutters h-100">
								<div
									:class="showCheckoutButton ? 'col-11' : 'col'"
									class="d-flex align-items-center">
									<div class="d-block w-100 pl-2">
										<div
											v-if="!!reachedPackage"
											class="row no-gutters w-100 justify-content-center">
											<div class="col-auto">
												<span
													class="text-discount text-medium"
													v-html="translate('complete_your_order_to_upgrade', { package: translate(reachedPackage.code_name) })" />
											</div>
										</div>
										<div
											v-if="!isTopPackageReached && !isVolumeExact"
											class="row no-gutters w-100 justify-content-center">
											<div class="col-auto">
												<span class="text-medium font-weight-bold">
													{{ translate('bv_to_upgrade', { volume: volumeToNextPackage, package: translate(targetPackage.code_name) }) }}
												</span>
											</div>
										</div>
									</div>
								</div>
								<div
									v-if="showCheckoutButton"
									class="col-1 d-flex align-items-center">
									<div class="d-block w-100 pr-2 text-discount">
										<i
											class="fa fa-hand-o-right"
											aria-hidden="true" />
									</div>
								</div>
							</div>
						</div>
					</template>
					<template v-if="!compact">
						<div class="col">
							<div class="row no-gutters align-items-center mb-1">
								<div class="col-auto">
									<img
										v-if="initialPackageName !== 'no_package'"
										:src="require(`@/assets/images/themes/${themeName}/packages/${initialPackageName}_35x35_blue.png`)"
										width="35"
										class="img-fluid">
								</div>
								<div class="col mx-2">
									<b-progress
										:max="targetPackage.volume_required"
										variant="primary"
										height="3em"
										animated
										striped
										class="mt-auto">
										<b-progress-bar :value="currentVolume">
											<strong style="width: 100%; position: absolute; font-size: 0.875rem">
												<span class="text-dark bg-light h-100 rounded px-3">
													{{ currentVolume }} / {{ targetPackage.volume_required }} {{ translate('bv_added') }}
												</span>
											</strong>
										</b-progress-bar>
									</b-progress>
								</div>
								<div class="col-auto">
									<img
										:src="require(`@/assets/images/themes/${themeName}/packages/${targetPackage.code_name}_35x35_${isVolumeExact || isTopPackageReached ? 'green' : 'gray'}.png`)"
										width="35"
										class="img-fluid">
								</div>
							</div>
							<div class="row no-gutters">
								<div class="col d-flex align-items-center">
									<div class="d-block w-100 px-2">
										<div
											v-if="!!reachedPackage"
											class="row no-gutters w-100 justify-content-center">
											<div class="col-auto text-center text-discount">
												<span
													class="text-medium"
													v-html="translate('complete_your_order_to_upgrade', { package: translate(reachedPackage.code_name) })" />
												<i
													v-if="showCheckoutButton"
													class="fa fa-hand-o-right ml-2"
													aria-hidden="true" />
											</div>
										</div>
										<div
											v-if="!isTopPackageReached && !isVolumeExact"
											class="row no-gutters w-100 justify-content-center">
											<div class="col-auto text-center">
												<span class="text-medium font-weight-bold">
													{{ translate('bv_to_upgrade', { volume: volumeToNextPackage, package: translate(targetPackage.code_name) }) }}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { Products, Purchase } from '@/translations';

export default {
	name: 'UpgradeProgress',
	messages: [Products, Purchase],
	mixins: [WindowSizes],
	props: {
		packages: {
			type: Array,
			default: () => [],
		},
		currentVolume: {
			type: Number,
			default: 0,
		},
		card: {
			type: Boolean,
			default: false,
		},
		compact: {
			type: Boolean,
			default: false,
		},
		checkoutButton: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			themeName: process.env.VUE_APP_THEME,
		};
	},
	computed: {
		initialPackageName() {
			return this.$user.details().package || 'no_package';
		},
		nextPackage() {
			let index = 0;
			let currentPackage = this.packages[index];

			while (typeof currentPackage !== 'undefined' && currentPackage.volume_required <= this.currentVolume) {
				index += 1;
				currentPackage = this.packages[index];
			}

			return currentPackage || null;
		},
		targetPackage() {
			if (this.isVolumeExact) return this.reachedPackage;
			if (this.nextPackage) return this.nextPackage;
			return this.topPackage;
		},
		reachedPackage() {
			if (this.nextPackage) {
				return this.packages[this.nextPackage.index - 1] || null;
			}
			return this.topPackage;
		},
		volumeToNextPackage() {
			return this.targetPackage.volume_required - this.currentVolume;
		},
		topPackage() {
			return this.packages[this.packages.length - 1];
		},
		isTopPackageReached() {
			return !!this.reachedPackage && this.reachedPackage.id === this.topPackage.id;
		},
		isVolumeExact() {
			return !!this.reachedPackage && this.currentVolume === this.reachedPackage.volume_required;
		},
		showCheckoutButton() {
			return this.checkoutButton && !!this.reachedPackage;
		},
	},
	methods: {
		handleClick() {
			if (this.showCheckoutButton) {
				this.$router.push({ name: 'Purchase' }).catch(() => {});
			}
		},
	},
};
</script>
<style scoped>
	.upgrade-progress-container.can-checkout:hover .text-discount {
		text-decoration: underline;
	}
</style>
