<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<b-row v-if="showTitle">
			<b-col>
				<div class="h4 my-2">
					{{ translate('banktransfer') }}
				</div>
			</b-col>
		</b-row>
		<b-row class="row">
			<b-col>
				<b-alert
					:show="!paymentMethodEnabled"
					variant="danger"
					v-html="translate('banktransfer_unavailable', {
						url: $router.resolve({ name: redirectTo }).href,
						max: typeof paymentMethodConditions.max !== 'undefined' ? paymentMethodConditions.maxFormatted : '',
					})" />
				<template v-if="paymentMethodEnabled">
					<b-alert
						show
						variant="info"
						v-html="translate('banktransfer_details')" />
					<b-alert
						show
						variant="warning">
						{{ translate('banktransfer_disclaimer') }}
					</b-alert>
				</template>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { PaymentMethods, PurchasePayment } from '@/translations';

export default {
	name: 'BitcoinPayment',
	messages: [PaymentMethods, PurchasePayment],
	props: {
		showTitle: {
			type: Boolean,
			default: false,
		},
		paymentMethodConditions: {
			type: Object,
			default: () => ({}),
		},
		redirectTo: {
			type: String,
			default: 'Store',
		},
	},
	computed: {
		paymentMethodEnabled() {
			return this.paymentMethodConditions.isAvailable;
		},
	},
};
</script>
