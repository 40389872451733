<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="row mb-2 no-gutters">
		<div
			v-if="!paymentMethodsLoading"
			class="col">
			<div class="row">
				<div class="col-12">
					<li
						v-if="paymentMethods.length > 1"
						class="list-group-item border-0 rounded-0 pb-0">
						<div class="row">
							<div class="col-12">
								<div
									:class="['xs', 'sm', 'md'].includes(windowWidth) ? 'btn-group-vertical w-100' : 'btn-group'"
									class="btn-group-lg payment-method"
									role="group">
									<b-button
										v-for="item in paymentMethods"
										:key="item.id"
										:variant="selectedPaymentMethodName === item.attributes.method_code_name ? 'primary' : 'light'"
										@click="selectedPaymentMethodName = item.attributes.method_code_name">
										{{ translate(item.attributes.method_code_name) }}
									</b-button>
								</div>
							</div>
						</div>
					</li>
				</div>
			</div>
			<b-row
				v-if="canSplitWithWallet && canSplitWithWalletConsideringMinAmount && allowedPaymentMethodsWithWallet.includes(selectedPaymentMethodName)"
				class="mx-1">
				<b-col v-if="selectedPaymentMethodName">
					<switch-toggle
						id="split_payment"
						v-model="splitWithWallet"
						name="wallet_split"
						variant="success"
						class="mr-2"
						pill />
					<label for="wallet_split">
						{{ translate('split_between_two_payment_methods', { first: translate('cashondelivery'), second: translate(selectedPaymentMethodName) }) }}
					</label>
					<hr class="p-1 m-1">
				</b-col>
				<b-col
					v-if="showSectionsAsCards"
					cols="12">
					<b-alert
						show
						v-html="translate('split_with_wallet_instructions')" />
				</b-col>
			</b-row>
			<ul
				:class="{ 'mx-3': showSectionsAsCards }"
				class="list-group">
				<li
					v-if="showSectionsAsCards"
					class="list-group-item h-100 p-0">
					<div
						class="row p-2 border-bottom m-0 pl-2 alert-secondary-darker">
						<div class="col my-auto align-middle">
							<div class="h5 m-0">
								{{ translate('cashondelivery') }}
							</div>
						</div>
					</div>
					<div class="px-4 py-2">
						<b-row>
							<b-col cols="12">
								<wallet-payment
									:show-title="false"
									:wallet-balance-loading="walletBalanceLoading"
									:balance="balance"
									:base-balance="baseBalance"
									:show-base-balance="showBaseBalance"
									:wallet-type="walletType"
									:wallet-type-errors="validationErrors[`${stepValidation}.payment_method.wallet_type`]"
									:wallet-password-errors="validationErrors[`${stepValidation}.payment_method.wallet_password`]"
									@passwordChanged="walletPassword = $event" />
							</b-col>
							<b-col cols="6">
								<label
									for="amount"
									class="label">
									{{ translate('amount_to_pay_with_payment_method', { method: translate('cashondelivery') }) }}
									<span class="text-danger">*</span>
								</label>
							</b-col>
							<b-col cols="6">
								<input
									id="amount"
									v-model="walletSplitAmount"
									name="amount"
									type="number"
									class="form-control text-right"
									aria-describedby="amount"
									:placeholder="`${storeCurrency.symbol} ${(0).toFixed(storeCurrency.decimals)}`"
									@input="verifyAndFixWalletAmount">
							</b-col>
							<b-col cols="12">
								<template v-if="hasAmountError">
									<p
										v-for="error in validationErrors[`${stepValidation}.payment_method.wallet_split_amount`]"
										:key="error"
										class="custom-invalid-feedback animated fadeIn pl-2 mb-0 text-right"
										v-text="error" />
								</template>
							</b-col>
						</b-row>
					</div>
				</li>
				<li
					:class="{ 'border-0': !showSectionsAsCards }"
					class="list-group-item h-100 p-0">
					<div
						v-if="showSectionsAsCards"
						class="row p-2 border-bottom m-0 pl-2 alert-secondary-darker">
						<div class="col my-auto align-middle">
							<div class="h5 m-0">
								{{ translate(selectedPaymentMethodName) }}
							</div>
						</div>
					</div>
					<div class="pb-2">
						<div class="row">
							<b-col
								v-if="showSectionsAsCards"
								cols="12">
								<div class="d-flex justify-content-between align-items-center mt-3 px-4">
									<div>
										{{ translate('amount_to_pay_with_payment_method', { method: translate(selectedPaymentMethodName) }) }}:
									</div>
									<div v-if="!systemLoading">
										<b> {{ translate(storeCurrency.code.toLocaleLowerCase(), { amount: remainingForOtherMethod.toFixed(storeCurrency.decimals) }) }}</b>
									</div>
								</div>
							</b-col>
							<div class="col-12">
								<ul class="list-group w-100">
									<li class="list-group-item rounded-0 border-0 pb-0">
										<credit-card-payment
											v-if="isCardPaymentMethod"
											ref="creditCardPayment"
											:show-title="paymentMethods.length === 1"
											:payment-method-conditions="conditions"
											:card-id.sync="cardId"
											:card-id1="cardId1"
											:card-id2="cardId2"
											:can-split-payment="showSplitPayment && !splitWithWallet"
											:cart-total="cartTotal"
											:is-split-payment="splitPayment"
											:split-payment-initial-amount="splitAmount"
											:validation-errors="validationErrors"
											@update:cardId1="cardId1 = $event"
											@update:cardId2="cardId2 = $event"
											@update:splitPayment="splitPayment = $event"
											@update:splitPaymentAgreement="splitPaymentAgreement = $event"
											@update:splitAmount="splitAmount = $event"
											@addCreditCard="addCreditCard = $event"
											@addCreditCard1="addCreditCard1 = $event"
											@addCreditCard2="addCreditCard2 = $event"
											@loading="creditCardsLoading = $event" />
										<cash-payment
											v-if="selectedPaymentMethodName === 'checkmo'"
											:show-title="paymentMethods.length === 1"
											:payment-method-conditions="conditions" />
										<wallet-payment
											v-if="selectedPaymentMethodName === 'cashondelivery'"
											:show-title="paymentMethods.length === 1"
											:wallet-balance-loading="walletBalanceLoading"
											:balance="balance"
											:show-base-balance="showBaseBalance"
											:base-balance="baseBalance"
											:wallet-type="walletType"
											:wallet-type-errors="validationErrors[`${stepValidation}.payment_method.wallet_type`]"
											:wallet-password-errors="validationErrors[`${stepValidation}.payment_method.wallet_password`]"
											@passwordChanged="walletPassword = $event" />
										<bitcoin-payment
											v-if="selectedPaymentMethodName === 'banktransfer'"
											:show-title="paymentMethods.length === 1"
											:payment-method-conditions="conditions" />
										<sezzle-payment
											v-if="selectedPaymentMethodName === 'sezzle'"
											:show-title="paymentMethods.length === 1"
											:payment-method-conditions="conditions"
											sezzle-details="sezzle_details_purchase" />
										<oxxo-payment
											v-if="selectedPaymentMethodName === 'oxxopay'"
											:show-title="paymentMethods.length === 1"
											:payment-method-conditions="conditions" />
										<g-cash-payment
											v-if="selectedPaymentMethodName === 'gcash'"
											:show-title="paymentMethods.length === 1"
											:payment-method-conditions="conditions" />
										<pay-pal-payment
											v-if="selectedPaymentMethodName === 'lptpaypal'"
											:show-title="paymentMethods.length === 1"
											:payment-method-conditions="conditions"
											paypal-details="lptpaypal_details_purchase" />
									</li>
								</ul>
							</div>
						</div>
						<!-- Billing Address -->
						<template v-if="!CREDIT_CARD_FORM_METHODS.includes(selectedPaymentMethodName) && conditions.isAvailable">
							<div class="row">
								<div class="col-12">
									<ul class="list-group w-100">
										<li class="list-group-item border-left-0 border-right-0 border-bottom-0 rounded-0 pt-0">
											<div class="row mt-2">
												<div class="col-12">
													<div class="h4 mb-0">
														{{ translate('billing_address') }}
													</div>
												</div>
											</div>
											<div
												class="row mt-2">
												<div class="col-12">
													<div class="custom-control custom-checkbox">
														<input
															id="customCheck1"
															v-model="sameAsShipping"
															:disabled="!allowSameAsShipping"
															type="checkbox"
															class="custom-control-input">
														<label
															:class="!allowSameAsShipping ? 'text-muted' : ''"
															class="custom-control-label"
															for="customCheck1">{{ translate('same_as_shipping') }}</label>
													</div>
												</div>
											</div>
										</li>
									</ul>
								</div>
							</div>
							<template v-if="!sameAsShipping">
								<div class="px-2 pb-1">
									<div class="row px-1">
										<div class="col-12">
											<address-radio-button
												class="mw-100"
												:address-data="addressDataWithNoPickup"
												:address-loading="addressLoading"
												:form-import="form"
												:hide-new-address="addAddress"
												:display-add-new-address="false"
												:borderless="true"
												button-group-name="billing-address"
												@add-address="addressFormState" />
											<div
												v-if="!addAddress && !addressLoading"
												class="row">
												<div class="col-12 px-4">
													<hr class="mt-0">
												</div>
											</div>
											<div
												v-if="addAddress && !addressLoading"
												class="pt-3 px-2">
												<address-country-form
													address="billing"
													:market-country-code="country"
													:user-country="true"
													:form-import="form"
													:errors-import="addressErrors.errors"
													:prefix-errors="''"
													:display-cancel-button="false"
													:display-submit-button="false"
													:display-goback-button="false"
													:display-override-toggle="showOverrideValidation" />
											</div>
										</div>
									</div>
									<div
										v-if="!addressLoading"
										class="row px-2">
										<div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mr-auto">
											<button
												v-if="buttonStates.seeAddressList === 'enabled'"
												:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
												class="btn btn-link b-4 float-left"
												@click="addressFormState">
												{{ translate('see_address_list') }}
											</button>
											<button
												v-if="buttonStates.useAnotherAddress === 'enabled'"
												:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
												class="btn btn-link b-4 float-left"
												@click="addressFormState">
												{{ translate('use_another_address') }}
											</button>
										</div>
									</div>
									<div
										v-if="buttonStates.newAddressCancel !== 'hidden' || buttonStates.newAddressSave !== 'hidden'"
										class="row p-2">
										<div
											:class="['xs', 'sm'].includes(windowWidth) ? 'mt-3' : 'ml-auto'"
											class="col-12 col-md-auto">
											<div class="row no-gutters justify-content-end">
												<div
													:class="['xs', 'sm'].includes(windowWidth) ? 'mt-1' : 'mr-2'"
													class="col order-2 order-md-1">
													<b-button
														v-if="buttonStates.newAddressCancel !== 'hidden'"
														:disabled="buttonStates.newAddressCancel === 'disabled'"
														style="min-width: 150px"
														class="w-100"
														variant="light"
														size="lg"
														@click="addressFormState; $emit('cancel')">
														{{ translate('cancel') }}
													</b-button>
												</div>
												<div
													:style="['xs'].includes(windowWidth) ? '' : 'min-width: 200px;'"
													class="col col-md-6 order-1 order-md-2">
													<b-button
														v-if="buttonStates.newAddressSave !== 'hidden'"
														:disabled="buttonStates.newAddressSave === 'disabled'"
														class="w-100"
														style="min-width: 200px"
														variant="primary"
														size="lg"
														@click="newAddress(form)">
														{{ translate('continue') }}
													</b-button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</template>
						</template>
					</div>
				</li>
			</ul>
			<b-row
				no-gutters
				class="px-3 mt-3">
				<b-col>
					<b-alert
						:show="showSectionsAsCards"
						class="pb-1"
						variant="warning">
						<div class="form-check">
							<input
								id="walletAndOtherMethodTerms"
								v-model="walletSplitAgreement"
								name="walletAndOtherMethodTerms"
								class="form-check-input"
								type="checkbox">
							<label for="walletAndOtherMethodTerms">
								{{ translate('wallet_and_other_payment_method_terms', { name: appName }) }}
							</label>
						</div>
					</b-alert>
				</b-col>
			</b-row>
			<div
				v-if="buttonStates.mainCancel !== 'hidden' || buttonStates.mainSave !== 'hidden'"
				class="row px-3 pb-1">
				<div
					:class="['xs', 'sm'].includes(windowWidth) ? 'mt-3' : 'ml-auto'"
					class="col-12 col-md-auto">
					<div
						v-if="(typeof validationErrors[`${stepValidation}.billing.address_id`] !== 'undefined')"
						class="row p-2">
						<div
							id="address"
							class="col-12">
							<b-alert
								variant="danger"
								show>
								<div
									v-for="error in validationErrors[`${stepValidation}.billing.address_id`]"
									:key="error">
									<span
										v-text="error" />
									<br>
								</div>
							</b-alert>
						</div>
					</div>
					<div class="row no-gutters justify-content-end">
						<div
							v-if="buttonStates.mainCancel !== 'hidden'"
							:class="['xs', 'sm'].includes(windowWidth) ? 'mt-1' : 'mr-2'"
							class="col order-2 order-md-1">
							<b-button
								v-show="!updateSplitAmount"
								:disabled="validating || !!preparing || buttonStates.mainCancel === 'disabled' || !!systemLoading"
								style="min-width: 150px"
								class="w-100"
								variant="light"
								size="lg"
								@click="$emit('cancel')">
								{{ translate('cancel') }}
							</b-button>
						</div>
						<div
							v-if="buttonStates.mainSave !== 'hidden'"
							:style="['xs'].includes(windowWidth) ? '' : 'min-width: 200px;'"
							class="col col-md-6 order-1 order-md-2">
							<b-button
								:disabled="validating || !!preparing || buttonStates.mainSave === 'disabled' || !conditions.isAvailable || !!systemLoading || (splitPayment && !(splitPaymentAgreement && cardId1 && cardId2))"
								style="min-width: 200px"
								class="w-100"
								variant="primary"
								size="lg"
								@click="handleContinue()">
								<i
									v-if="validating"
									class="fas fa-spinner fa-spin mr-2" />
								{{ translate('continue') }}
							</b-button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="paymentMethodsLoading"
			class="col-12 mt-2 fade-in text-center d-flex justify-content-center align-items-center">
			<div class="col-12">
				<div class="h2">
					<i class="fa fa-fw fa-spinner fa-pulse" />
					<h4 class="mt-3">
						{{ translate('loading') }}
					</h4>
				</div>
			</div>
		</div>
		<same-address-as-card-statement-modal
			v-model="showSameAddressAsCardStatementModal"
			:country-to-check="country"
			:card-type="selectedCardInfo().card_type"
			:card-type2="selectedCard2Info().card_type"
			:last-digits="selectedCardInfo().last_digits"
			:last-digits2="selectedCard2Info().last_digits"
			:address="selectedCardInfo().address"
			@confirm="prepareForStorage()" />
	</div>
</template>
<script>
import { CREDIT_CARD_FORM_METHODS } from '@/settings/CreditCard';
import {
	ALLOWED_PAYMENT_METHODS_WITH_WALLET,
	MIN_AMOUNT_BY_PAYMENT_METHODS_WITH_WALLET,
	CC_PAYMENT_METHOD_BY_COUNTRY,
} from '@/settings/Purchase';
import { WALLET_TYPES } from '@/settings/Wallets';
import {
	FORBIDDEN, NOT_FOUND, UNPROCESSABLE, SYSTEM_ALERT,
} from '@/settings/Errors';
import WindowSizes from '@/mixins/WindowSizes';
import AddressRadioButton from '@/components/AddressRadioButton';
import AddressCountryForm from '@/components/AddressCountryForm';
import AddressBook from '@/util/AddressBook';
import Country from '@/util/Country';
import Cart from '@/util/Cart';
import CreditCard from '@/util/CreditCard';
import Wallets from '@/util/Wallets';
import CommonMix from '../../mixins/Common';
import Addresses from '../../mixins/Addresses';
import Steps from '../../mixins/Steps';
import store from '../../store';
import PaymentMethods from '@/mixins/PaymentMethods';
import SwitchToggle from '@/components/Switch';
import { currency } from '@/config/Filters';
import WalletPayment from '@/components/Payment/WalletPayment.vue';
import CashPayment from '@/components/Payment/CashPayment.vue';
import BitcoinPayment from '@/components/Payment/BitcoinPayment.vue';
import SezzlePayment from '@/components/Payment/SezzlePayment.vue';
import OxxoPayment from '@/components/Payment/OxxoPayment.vue';
import GCashPayment from '@/components/Payment/GCashPayment.vue';
import PayPalPayment from '@/components/Payment/PayPalPayment.vue';
import CreditCardPayment from '@/components/Payment/CreditCardPayment.vue';
import SameAddressAsCardStatementModal from '@/components/SameAddressAsCardStatementModal/index.vue';

export default {
	name: 'PaymentEdit',
	components: {
		SameAddressAsCardStatementModal,
		AddressRadioButton,
		AddressCountryForm,
		SwitchToggle,
		WalletPayment,
		CashPayment,
		BitcoinPayment,
		SezzlePayment,
		OxxoPayment,
		GCashPayment,
		PayPalPayment,
		CreditCardPayment,
	},
	filters: {
		currency,
	},
	mixins: [Addresses, PaymentMethods, CommonMix, Steps, WindowSizes],
	props: {
		cartId: {
			type: [String, Number],
			default: '',
		},
		walletSplit: {
			type: Boolean,
			default: false,
		},
		canSplitWithWallet: {
			type: Boolean,
			default: false,
		},
		cartTotal: {
			type: Number,
			default: 0,
		},
		cartNumericTotal: {
			type: Number,
			default: 0,
		},
		systemLoading: {
			type: Boolean,
			default: false,
		},
		canSplitPayment: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			createAddress: new AddressBook(),
			state: new Country(),
			paymentMethodData: new Cart(),
			creditCard: new CreditCard(),
			wallets: new Wallets(),
			form: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				postcode: '',
				city: '',
				country_code: '',
				region_id: '',
				address_id: 0,
			},
			alert: new this.$Alert(),
			purchaseCreditCardsErrors: {},

			allowSameAsShipping: true,
			sameAsShipping: false,
			walletTypes: WALLET_TYPES,
			billingAddressId: 0,
			cardId: 0,
			creditCardsLoading: false,
			selectedPaymentMethodName: 0,
			walletPassword: '',
			walletType: WALLET_TYPES.commission,

			addCreditCard: false,
			addAddress: false,
			showOverrideValidation: false,
			showMainCancel: true,
			splitWithWallet: this.walletSplit,
			walletSplitAmount: '',
			walletSplitAgreement: false,
			updateSplitAmount: false,
			allowedPaymentMethodsWithWallet: ALLOWED_PAYMENT_METHODS_WITH_WALLET,
			minAmountByPaymentMethodWithWallet: MIN_AMOUNT_BY_PAYMENT_METHODS_WITH_WALLET,
			appName: process.env.VUE_APP_NAME,
			showSameAddressAsCardStatementModal: false,
			CREDIT_CARD_FORM_METHODS,

			// Split Payments
			cardId1: 0,
			cardId2: 0,
			splitPayment: false,
			splitPaymentAgreement: false,
			splitAmount: null,
			addCreditCard1: false,
			addCreditCard2: false,
		};
	},
	computed: {
		isCardPaymentMethod() {
			return CREDIT_CARD_FORM_METHODS.includes(this.selectedPaymentMethodName);
		},
		showSectionsAsCards() {
			return this.splitWithWallet && this.allowedPaymentMethodsWithWallet.includes(this.selectedPaymentMethodName);
		},
		storedShippingData() {
			return JSON.parse(JSON.stringify(store.state.PurchaseShipping));
		},
		storeCurrency() {
			try {
				return this.paymentMethodData.data.response.data.meta.currency;
			} catch (error) {
				return {
					code: 'usd',
					symbol: '$',
					decimals: 2,
				};
			}
		},
		paymentMethods() {
			try {
				return this.paymentMethodData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		ignoreLimitsFor() {
			try {
				return this.paymentMethodData.data.response.data.meta.ignore_limits_for;
			} catch (e) {
				return [];
			}
		},
		balance() {
			try {
				return this.wallets.data.response.data.response.total_balance;
			} catch (error) {
				return {};
			}
		},
		baseBalance() {
			try {
				return this.wallets.data.response.data.response.base_total_balance;
			} catch (error) {
				return {};
			}
		},
		showBaseBalance() {
			try {
				return this.wallets.data.response.data.response.show_base_balance;
			} catch (error) {
				return false;
			}
		},
		paymentMethodsLoading() {
			return !!this.paymentMethodData.data.loading;
		},
		walletBalanceLoading() {
			return !!this.wallets.data.loading;
		},
		addressErrors() {
			return this.createAddress.data.errors;
		},
		stepValidation() {
			return this.getStepValidationByName('PurchasePayment');
		},
		createAddressLoading() {
			return this.createAddress.data.loading;
		},
		showSplitPayment() {
			try {
				return this.canSplitPayment;
			} catch (error) {
				return false;
			}
		},
		buttonStates() {
			const initialInfoIsComplete = !!this.initialInformation.credit_card.card || !Array.isArray(this.initialInformation.credit_card.billing_address);
			const storedInfoIsComplete = store.state.completedSteps.includes('PurchasePayment');
			const hasInitialOrStoredData = initialInfoIsComplete || storedInfoIsComplete;

			const {
				// validating,
				// preparing,
				addAddress,
				selectedPaymentMethodName,
				addCreditCard,
				addCreditCard1,
				addCreditCard2,
				hasAddressData,
				paymentMethodsLoading,
				loadingInitialInformation,
				addressLoading,
				creditCardsLoading,
				createAddressLoading,
				splitWithWallet,
				walletSplitAgreement,
			} = this;

			const loadingAny = [
				paymentMethodsLoading,
				loadingInitialInformation,
				addressLoading,
				creditCardsLoading,
				createAddressLoading,
			].some((loading) => loading);

			const buttonStates = {
				mainSave: 'enabled', // can disable
				mainCancel: 'enabled', // can disable
				newAddressSave: 'enabled', // can disable
				newAddressCancel: 'enabled', // can disable
				seeAddressList: 'enabled',
				useAnotherAddress: 'enabled',
			};

			if (loadingAny) {
				Object.keys(buttonStates).forEach((key) => {
					buttonStates[key] = 'disabled';
				});
			}

			if (!hasInitialOrStoredData) {
				buttonStates.mainCancel = 'hidden';
				buttonStates.newAddressCancel = 'hidden';
			}

			if (!CREDIT_CARD_FORM_METHODS.includes(selectedPaymentMethodName)) {
				if (addAddress) {
					buttonStates.mainCancel = 'hidden';
					buttonStates.mainSave = 'hidden';
					buttonStates.useAnotherAddress = 'hidden';
				} else {
					buttonStates.newAddressCancel = 'hidden';
					buttonStates.newAddressSave = 'hidden';
					buttonStates.mainSave = 'enabled';
					buttonStates.seeAddressList = 'hidden';
				}

				if (!hasAddressData) {
					buttonStates.seeAddressList = 'hidden';
					buttonStates.newAddressCancel = 'hidden';
				}
			}

			if (CREDIT_CARD_FORM_METHODS.includes(selectedPaymentMethodName)) {
				buttonStates.newAddressCancel = 'hidden';
				buttonStates.newAddressSave = 'hidden';
				buttonStates.seeAddressList = 'hidden';
				buttonStates.useAnotherAddress = 'hidden';

				if ((addCreditCard && !this.splitPayment) || ((addCreditCard1 || addCreditCard2) && this.splitPayment)) {
					buttonStates.mainCancel = 'hidden';
					buttonStates.mainSave = 'hidden';
				} else if (splitWithWallet && !walletSplitAgreement) {
					buttonStates.mainSave = 'disabled';
				}
			} else if (this.allowedPaymentMethodsWithWallet.includes(selectedPaymentMethodName) && splitWithWallet && !walletSplitAgreement) {
				buttonStates.mainSave = 'disabled';
			}

			this.$emit('changePaymentMethodSelected', selectedPaymentMethodName, this.conditions);
			return buttonStates;
		},
		creditCardPaymentMethod() {
			return CC_PAYMENT_METHOD_BY_COUNTRY[this.country] ?? CC_PAYMENT_METHOD_BY_COUNTRY.default;
		},
		hasAmountError() {
			try {
				return !!this.validationErrors[`${this.stepValidation}.payment_method.wallet_split_amount`].length;
			} catch (e) {
				return false;
			}
		},
		canSplitWithWalletConsideringMinAmount() {
			const minAmount = this.minAmountByPaymentMethodWithWallet[this.selectedPaymentMethodName] ?? 0;
			return this.cartTotal > minAmount;
		},
		remainingForOtherMethod() {
			return Number(this.cartTotal - this.walletSplitAmount);
		},
		conditions() {
			let conditions = { ...(this.paymentMethodConditions[this.selectedPaymentMethodName] || { isAvailable: true }) };
			if (Object.keys(conditions).length > 0 && typeof conditions.max !== 'undefined' && this.showSectionsAsCards) {
				conditions = { ...conditions, isAvailable: this.remainingForOtherMethod <= conditions.max };
			}
			if (this.ignoreLimitsFor.includes(this.selectedPaymentMethodName)) {
				conditions = { ...conditions, isAvailable: true };
			}
			conditions = { ...conditions, ...this.storeCurrency };
			return conditions;
		},
		addressDataWithNoPickup() {
			return this.addressData.filter((address) => !address.attributes.is_pickup_address);
		},
	},
	watch: {
		sameAsShipping(value) {
			if (value) {
				this.addAddress = false;
			} else {
				this.initializeFormValues();
				this.initializeBillingAddress();
			}
			this.clearValidationError(true);
		},
		'form.address_id': function addressId(newVal) {
			this.billingAddressId = newVal;
		},
		hasAddressData: {
			deep: true,
			handler(newVal) {
				this.addAddress = !newVal;
			},
		},
		storedShippingData: {
			deep: true,
			handler() {
				const shippingAddressId = this.getStoredShippingAddressId();
				if (shippingAddressId) {
					this.allowSameAsShipping = false;
					this.refreshAddressBook().then(() => {
						if (this.sameAsShipping) {
							this.form.address_id = shippingAddressId;
						}
					});
				}
			},
		},
		splitWithWallet(split) {
			this.$emit('update:walletSplit', split);
		},
		canSplitWithWalletConsideringMinAmount(canSplitWithWallet) {
			if (!canSplitWithWallet) {
				this.splitWithWallet = false;
			}
		},
		selectedPaymentMethodName() {
			this.showSameAddressAsCardStatementModal = false;
			this.addCreditCard = false;
		},
		systemLoading(newVal) {
			this.updateSplitAmount = true;
			if (newVal) {
				this.walletSplitAmount = '';
			}
		},
		splitPayment(newVal) {
			this.$emit('splitPayment', newVal);
		},
		cartNumericTotal(total) {
			this.setAmountToPay(total);
		},
		paymentMethods: {
			deep: true,
			handler() {
				const transformedPaymentMethods = {};
				this.paymentMethods.forEach((method) => {
					transformedPaymentMethods[method.attributes.method_code_name] = method.attributes;
				});
				this.setMethodLimits(transformedPaymentMethods);
			},
		},
	},
	async mounted() {
		this.form.country_code = this.country;
		this.getStoredInformation();
		this.initializeFormValues();
		this.initializeBillingAddress();
		this.initializeSameAsShipping();

		await this.paymentMethodData.getPaymentMethods({ country_code: this.getStoredCountry(), cart_id: this.$cookie.get('cart_id'), flow: 'purchase' });
		if (this.selectedPaymentMethodName === 0) {
			this.selectedPaymentMethodName = this.paymentMethods[0].attributes.method_code_name;
		}
		if (this.paymentMethods.some((method) => method.attributes.method_code_name === 'cashondelivery')) {
			this.walletType = this.walletTypes.commission;
			this.wallets.getBalance();
		}
	},
	methods: {
		selectedCardInfo() {
			try {
				if (this.splitPayment) {
					if (this.cardId1) {
						// eslint-disable-next-line eqeqeq
						return this.$refs.creditCardPayment.creditCardsData.find((card) => card.id == this.cardId1).attributes;
					}
				} else if (this.cardId) {
					// eslint-disable-next-line eqeqeq
					return this.$refs.creditCardPayment.creditCardsData.find((card) => card.id == this.cardId).attributes;
				}
			} catch (error) {
				return {};
			}
			return {};
		},
		selectedCard2Info() {
			try {
				if (this.splitPayment) {
					if (this.cardId2) {
						// eslint-disable-next-line eqeqeq
						return this.$refs.creditCardPayment.creditCardsData.find((card) => card.id == this.cardId2).attributes;
					}
				}
			} catch (error) {
				return {};
			}
			return {};
		},
		handleContinue() {
			if (this.isCardPaymentMethod) {
				if (this.splitPayment) {
					this.confirmSplitPayment();
				} else {
					this.showSameAddressAsCardStatementModal = true;
				}
			} else {
				this.prepareForStorage();
			}
		},
		confirmSplitPayment() {
			const trans = {
				title: this.translate('important_note').toUpperCase(),
				text: this.translate('split_payment_disclaimer'),
			};

			const options = {
				config: {
					icon: 'warning',
					confirmButtonText: this.translate('understand'),
					cancelButtonText: this.translate('cancel'),
					allowOutsideClick: false,
					allowEscapeKey: false,
					confirmButtonColor: '#eb772f',
					showCancelButton: true,
					reverseButtons: true,
				},
			};

			const alert = new this.$Alert();
			alert.confirmation(trans.title, trans.text, options).then(() => {
				this.showSameAddressAsCardStatementModal = true;
			}).catch(() => {});
		},
		verifyAndFixWalletAmount() {
			const minAmount = this.minAmountByPaymentMethodWithWallet[this.selectedPaymentMethodName] ?? 0;
			const maxWalletAmount = Number(Math.max(this.cartTotal - minAmount, 0).toFixed(2));

			if (this.walletSplitAmount > maxWalletAmount) {
				this.walletSplitAmount = maxWalletAmount;
			}
		},
		getStoredInformation() {
			if (store.getters.getCompletedSteps().includes('PurchasePayment')) {
				const stepPaymentInfo = this.getStepInformation('PurchasePayment').payment;
				// This linting disabler must be removed when the linter is upgraded
				// See: https://github.com/babel/eslint-plugin-babel/pull/163
				// eslint-disable-next-line camelcase
				this.splitPayment = stepPaymentInfo.payment_method?.split || false;
				// eslint-disable-next-line camelcase
				this.splitAmount = this.splitPayment ? (stepPaymentInfo.payment_method?.split_amount || null) : null;
				// eslint-disable-next-line camelcase
				this.cardId = stepPaymentInfo.payment_method?.card_id || 0;
				// eslint-disable-next-line camelcase
				this.cardId1 = this.splitPayment ? (stepPaymentInfo.payment_method?.card_id || 0) : 0;
				// eslint-disable-next-line camelcase
				this.cardId2 = this.splitPayment ? (stepPaymentInfo.payment_method?.card_id_2 || 0) : 0;
				// eslint-disable-next-line camelcase
				this.selectedPaymentMethodName = stepPaymentInfo.payment_method?.name || this.creditCardPaymentMethod;
				// eslint-disable-next-line camelcase
				this.walletType = stepPaymentInfo.payment_method?.wallet_type || WALLET_TYPES.commission;
				// eslint-disable-next-line camelcase
				this.walletSplitAmount = stepPaymentInfo.payment_method?.wallet_split_amount || 0;
			} else if (!Array.isArray(this.initialInformation.credit_card.card)) {
				if (this.initialInformation.credit_card.card) {
					this.cardId = this.initialInformation.credit_card.card.id;
				}
			}
			// } else {
			// 	this.showMainCancel = false;
			// }
		},
		async prepareForStorage() {
			this.preparing = true;

			const paymentMethodName = this.selectedPaymentMethodName;
			const billingAddressId = this.sameAsShipping ? this.getStoredShippingAddressId() : this.billingAddressId;
			const payload = { cart_id: this.cartId };

			if (CREDIT_CARD_FORM_METHODS.includes(paymentMethodName)) {
				payload.payment = {
					payment_method: {
						name: paymentMethodName,
						card_id: this.splitPayment ? Number(this.cardId1) : this.cardId,
						card_id_2: this.splitPayment ? Number(this.cardId2) : null,
						split_amount: this.splitPayment ? this.splitAmount : null,
						split: this.splitPayment,
					},
				};
			} else if (paymentMethodName === 'cashondelivery') {
				payload.payment = {
					billing: {
						address_id: billingAddressId,
					},
					payment_method: {
						name: paymentMethodName,
						wallet_password: this.walletPassword,
						wallet_type: this.walletType,
					},
				};
			} else if (paymentMethodName === 'checkmo') {
				payload.payment = {
					billing: {
						address_id: billingAddressId,
					},
					payment_method: {
						name: paymentMethodName,
					},
				};
			} else if (paymentMethodName === 'banktransfer') {
				payload.payment = {
					billing: {
						address_id: billingAddressId,
					},
					payment_method: {
						name: paymentMethodName,
					},
				};
			} else if (['sezzle', 'oxxopay', 'lptpaypal'].includes(paymentMethodName)) {
				payload.payment = {
					billing: {
						address_id: billingAddressId,
					},
					payment_method: {
						name: paymentMethodName,
						// eslint-disable-next-line no-underscore-dangle
						sift_session_id: window._sift_session_id,
					},
				};
			} else if (paymentMethodName === 'gcash') {
				payload.payment = {
					billing: {
						address_id: billingAddressId,
					},
					payment_method: {
						name: paymentMethodName,
					},
				};
			}

			const walletSplitSettings = this.showSectionsAsCards ? {
				wallet_split: this.splitWithWallet,
				wallet_split_amount: this.walletSplitAmount || 0,
				wallet_password: this.walletPassword,
				wallet_type: WALLET_TYPES.commission,
			} : { wallet_split: false };

			payload.payment.payment_method = { ...payload.payment.payment_method, ...walletSplitSettings };

			this.updatePurchase(payload).then(() => {
				if (CREDIT_CARD_FORM_METHODS.includes(payload.payment.payment_method.name)) {
					this.creditCard.getCreditCard(this.$user.details().id, payload.payment.payment_method.card_id).then((response) => {
						const cardAddress = response.attributes.address;
						payload.payment.billing = {
							...payload.payment.billing,
							city: cardAddress.city,
							country_id: cardAddress.country.code,
							firstname: cardAddress.first_name,
							lastname: cardAddress.last_name,
							postcode: cardAddress.postcode,
							region: {
								region: cardAddress.region.name,
								region_code: cardAddress.region.code,
								region_id: cardAddress.region.id,
							},
							street: [cardAddress.address, cardAddress.address2],
						};
						this.saveStep(payload, 'PurchasePayment');
						this.updateSplitAmount = false;
					});
				} else {
					this.saveStep(payload, 'PurchasePayment');
					this.updateSplitAmount = false;
				}
			}).catch((error) => {
				if ([...NOT_FOUND, ...FORBIDDEN].includes(error.status)) {
					this.$emit('invalidRequest', error);
				}
				if (UNPROCESSABLE.includes(error.status)) {
					const { cart_id: cartId } = error.errors;
					if (typeof cartId !== 'undefined') {
						let response = '';
						cartId.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 6000 });
						setTimeout(() => {
							this.$emit('cartValidationError');
						}, 6000);
					}
					if (typeof this.validationErrors.identity_id !== 'undefined') {
						this.alert.toast('error', this.translate('identity_error'), { timer: 6000 });
					}
					if (typeof this.validationErrors[`${this.stepValidation}.payment_method.token`] !== 'undefined') {
						this.purchaseCreditCardsErrors.number.errorMessages = this.validationErrors[`${this.stepValidation}.payment_method.token`];
					}
				}

				const cardVerification = error?.errors['payment.payment_method.card_id'][0];
				if (cardVerification !== undefined) {
					this.alert.toast('error', cardVerification, { timer: 6000 });
				}
			}).finally(() => { this.preparing = false; });
			return null;
		},
		handleAddressFormChange(target) {
			if (target === 'billing.billing.country_code') {
				this.stateData.getStates(this.form.country_code).then(() => {
					this.form.region_id = '';
				});
			}
			this.clearValidationError(target);
		},
		creditCardHasErrors(field) {
			if (typeof this.purchaseCreditCardsErrors[field] !== 'undefined' && this.purchaseCreditCardsErrors[field].errorMessages.length > 0) {
				return true;
			}
			return false;
		},
		// Billing Address
		initializeBillingAddress() {
			this.refreshAddressBook().then((response) => {
				if (!this.form.address_id && response.length) {
					const address = response.find((item) => !item.attributes.is_pickup_address);
					if (address) this.form.address_id = address.id;
				}
			});
		},
		initializeFormValues() {
			this.form = {
				...this.form,
				first_name: this.$user.details().name,
				last_name: this.$user.details().last_name,
				address: '',
				address2: '',
				postcode: '',
				city: '',
				country_code: this.country,
				region_id: '',
			};
		},
		initializeSameAsShipping() {
			if (!this.getStoredShippingAddressId()) {
				this.allowSameAsShipping = false;
				this.sameAsShipping = false;
			}
		},
		newAddress(form) {
			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'), options);

			const payload = { ...form };
			if (!this.showOverrideValidation) {
				payload.override_validation = undefined;
			}
			payload.address_type = 'billing';

			this.createAddress.saveAddress(this.$user.details().id, payload).then(() => {
				this.alert.toast('success', this.translate('address_created'), { timer: 6000 });

				this.addressBook.getAddresses(this.$user.details().id).then((response) => {
					if (response.length) {
						const lastAddressPos = response.length - 1;
						this.form.address_id = response[lastAddressPos].id;
					}
				}).then(() => this.prepareForStorage()).finally(() => { this.addAddress = false; });
			}).catch(() => {
				if (UNPROCESSABLE.includes(this.addressErrors.status)) {
					Object.keys(this.addressErrors.errors).forEach((errorKey) => {
						if (errorKey === 'suggestion') {
							this.showSuggestionAlert(form);
						} else if (
							errorKey === 'address'
							&& !!this.form.address.trim().length
							&& this.addressVerificationCountries.includes(this.form.country_code)
						) {
							this.showOverrideValidation = true;
						}
					});
				}
				if (SYSTEM_ALERT.includes(this.addressErrors.status) || this.addressErrors.status === undefined) {
					this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				} else {
					this.alert.close();
				}
			});
		},
		showSuggestionAlert(form) {
			this.getSuggestion.getSuggestion(form).then((suggestionResponse) => {
				let address = '';
				const suggestedAddress = suggestionResponse.response.address;

				Object.keys(suggestedAddress).forEach((item, index) => {
					if (suggestedAddress[item] !== '' && item !== 'region_id') {
						address += `${suggestedAddress[item]}`;
						if (index < (Object.keys(suggestedAddress).length) - 2) {
							address += ', ';
						}
					}
				});
				const trans = {
					title: this.translate('invalid_address'),
					text: this.translate('suggestion_address', { address }),
				};
				const options = {
					config: {
						icon: 'warning',
						showCancelButton: true,
						confirmButtonText: this.translate('accept_sugestion'),
						cancelButtonText: this.translate('manual_change'),
						reverseButtons: true,
						allowOutsideClick: false,
						allowEscapeKey: false,
					},
				};
				this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
					Object.keys(suggestedAddress).forEach((suggestionItem) => {
						Object.keys(this.form).forEach((formItem) => {
							if (formItem === suggestionItem) {
								this.form[formItem] = suggestedAddress[suggestionItem];
							}
						});
					});
					this.newAddress(this.form);
				}).catch(() => {});
			}).catch(() => {});
		},
		clearAddressForm() {
			this.form.first_name = '';
			this.form.last_name = '';
			this.form.address = '';
			this.form.address2 = '';
			this.form.city = '';
			this.form.postcode = '';
			// this.form.country_code = '';
			this.form.region_id = '';
			this.form.override_validation = false;
			this.showOverrideValidation = false;
		},
		addressFormState() {
			if (!this.addAddress) {
				this.clearAddressForm();
			}
			if (Object.keys(this.addressErrors.errors).length > 0) {
				const errorFields = Object.keys(this.addressErrors.errors);
				errorFields.forEach((field) => {
					delete this.addressErrors.errors[field];
					this.addressErrors.errors = { ...this.addressErrors.errors };
				});
			}
			this.clearValidationError(true);
			this.addAddress = !this.addAddress;
		},
		updateSplitPaymentValue(newVal) {
			this.$refs.creditCardPayment.updateSplitPaymentValue(newVal);
		},
	},
};
</script>
